import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useGuestRedirection } from '../../services/Hooks';
import { ME } from '../../services/Queries';
import Loading from '../Loading/Loading';

const Welcome = () => {

    useGuestRedirection();

    const { data, loading } = useQuery(ME);

    if (loading) {
        return <Loading />;
    }

    return <div>
        Welcome, {data.me.name} your email is {data.me.isEmailVerified ? 'VERIFIED' : 'NOT_VERIFIED'}
    </div>;
};

export default Welcome;
