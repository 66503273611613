import React, { useState, useEffect } from 'react';
import { useFocus } from '../../services/Hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

export type MyFakeEvent = {
    target: {
        name: string,
        value: string
    }
}

export type SearchProps = {
    /**
     * The value to pass to the underlying TextField
     */
    value: string;
    /**
     * The value to pass to the underlying TextField
     */
    onChange: (event: MyFakeEvent) => void;
    /**
     * The interval in ms to use as debounce
     */
    interval?: number;
    /**
     * The value to pass to the underlying TextField
     */
    placeholder?: string;
    /**
     * Add an end ornament with a clear icon
     */
    clearable?: boolean
}


const ClearSearch = (props: any) => {
    return (
        <InputAdornment position="end">
            {props.hide && <IconButton onClick={props.onClick} size="small">
                <ClearIcon />
            </IconButton>}
        </InputAdornment>
    )
}

export const Search = (props: SearchProps) => {

    console.log('rendering search')

    const { interval, value, onChange, placeholder } = props

    const _placeholder = placeholder || 'pesquisar';
    const _interval = interval || 500

    const [data, setData] = useState(value);
    const [inputRef, setInputFocus] = useFocus();

    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (data !== undefined) {
                const event = buildFakeEvent(data)
                onChange(event);
            }
        }, _interval);
        return () => window.clearTimeout(timer);
    });

    useHotkeys('s', event => {
        event.preventDefault();
        // @ts-ignore
        setInputFocus();
    });


    const handleChange = (event: any) => {
        setData(event.target.value);
    };

    const buildFakeEvent = (data: string): MyFakeEvent => {
        return {
            target: {
                name: "search",
                value: data
            }
        };
    }

    const handleClear = () => {
        setData("");
        const event = buildFakeEvent("")
        onChange(event)
    }

    return (
        <TextField
            name="search"
            placeholder={_placeholder}
            value={data || ''}
            onChange={handleChange}
            inputRef={inputRef}
            autoComplete="off"
            InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                endAdornment: <ClearSearch onClick={handleClear} hide={data} />
            }}
        />
    );
};


export default Search;
