import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { OrderDirection, useProcessesQuery } from '../../generated/graphql';
import Loading from '../Loading/Loading';


const ProcessSelector = ({ value, onChange, variant = "standard" }: { value: any, onChange: any, variant?: any }) => {

    const organization = localStorage.getItem('auth.organization')
    const { data, loading } = useProcessesQuery({ variables: { organization, pageSize: 999, order: { field: "name", direction: OrderDirection.Asc } } })


    if (loading) {
        return <Loading />;
    }

    const handle = (_: any, data: any) => {
        const event = {
            target: { name: "process", value: data ? data.id : null }
        }
        onChange(event)
    }

    const setValue = () => {
        // @ts-ignore: Object is possibly 'null'.
        return data?.processes?.results.find(process => process?.id === value)
    }

    return (
        <Autocomplete
            id="process"
            options={data?.processes?.results as any}
            onChange={handle}
            value={setValue()}
            getOptionLabel={(process: any) => process?.name}
            renderInput={(params) => <TextField {...params} label="Processo" variant={variant} />}
        />
    )


}


export default ProcessSelector;
