import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import querySearch from "stringquery";
import { useActivateMutation } from '../../generated/graphql';
import Loading from '../Loading/Loading';

const Activate = () => {

    const [verifying, setVerifying] = useState();
    const location = useLocation();
    const query = querySearch(location.search);
    const { email, token } = query;
    const [activate] = useActivateMutation()

    const handleVerification = (response: any) => {
        if (response?.data?.activate?.errors) {
            setVerifying('FAILURE');
        } else {
            setVerifying('SUCCESS');
        }
    };

    if (!verifying) {
        activate({ variables: { email, token } }).then(handleVerification);
        setVerifying('PROGRESS');
    }

    if (verifying) {
        return verifying === 'PROGRESS' ? <Loading /> : <div>{verifying}</div>;
    }

    return null;

};

export default Activate;
