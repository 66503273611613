import { useMutation } from '@apollo/react-hooks';
import { Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import * as Yup from "yup";
import { useLoginMutation } from '../../generated/graphql';
import { useUserRedirection } from '../../services/Hooks';
import { LOGIN_USER } from '../../services/Local';
import { VALID_EMAIL_PLEASE, VALID_PASSWORD_PLEASE, WRONG_CREDENTIALS } from '../../services/UserMessages';
import styles from './Login.module.scss';

const Login = () => {

    const [login] = useLoginMutation();
    const [loginUser] = useMutation(LOGIN_USER);
    const [validationError, setValidationError] = useState({ message: '' });

    useUserRedirection();

    const loginSchema = Yup.object().shape({
        email: Yup.string().email(VALID_EMAIL_PLEASE).required(VALID_EMAIL_PLEASE),
        password: Yup.string().required(VALID_PASSWORD_PLEASE)
    });

    return (
        <Grid container justify="center" alignItems="center" className={styles.Login}>
            <Grid item xs={3}>
                <Grid container direction="column">
                    <h1>Register</h1>
                    <Card>
                        <CardContent>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',

                                }}
                                validationSchema={loginSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    login({ variables: values }).then((response) => {
                                        setSubmitting(false);
                                        if (response?.data?.login?.token) {
                                            loginUser({ variables: response?.data?.login });
                                        } else {
                                            setValidationError({ message: WRONG_CREDENTIALS });
                                        }
                                    });
                                }}
                            >
                                {({ isValid, dirty, submitForm, isSubmitting }) => (
                                    <Form>
                                        <Grid container direction="column">

                                            <Field
                                                component={TextField}
                                                name="email"
                                                type="email"
                                                label="Email"
                                                required
                                                data-cy="email"
                                            />
                                            <Field
                                                component={TextField}
                                                name="password"
                                                type="password"
                                                label="Password"
                                                required
                                                data-cy="password"
                                            />

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !isValid || !dirty}
                                                onClick={submitForm}
                                                data-cy="submit"
                                            >
                                                Submeter
					    </Button>


                                            <div data-cy="errors" className={styles.ValidationError}>
                                                {validationError && validationError.message}
                                            </div>

                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default Login;
