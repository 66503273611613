import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import React from 'react';
import { humanizedDate } from '../../constants';
import { Issue } from '../../generated/graphql';
import { issueNatures, issueStates } from '../../utils/Utils';
import FormatDate from '../FormatDate/FormatDate';
import styles from './IssueDetails.module.scss';

const TagsDisplay = ({ tags }: { tags: any }) => {
    if (!tags?.length) return null
    return (
        <Grid container alignItems="center" className={styles.TagsDisplay}>
            <LocalOfferIcon />
            {tags.map((tag: any) => (
                <span key={tag.id} className={styles.Tag}>
                    {tag.name}
                </span>)
            )}
        </Grid>
    )
};

const IssueDetails = ({ issue, onComplete, onEdit }: { issue: Issue, onComplete: () => any, onEdit: () => any }) => {

    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.Wrapper}>

            <Grid container justify="space-between" alignItems="center" className={styles.Actions}>
                <div>
                    <span className={styles.State}>P{issue.priority}</span>
                    <span className={styles.State}>{issueNatures(issue.nature)}</span>
                    <span className={styles.State}>{issueStates(issue.state)}</span>
                </div>
                <Box>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                    {/* <IconButton onClick={() => alert('not implemented yet')}>
                        <DeleteIcon />
			</IconButton> */}
                    <IconButton onClick={onComplete}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Grid>

            <p className={styles.Name}>
                {'#' + issue.sequence} - {issue.name || 'Sem Nome'}
            </p>
            <p className={styles.Process}>
                {issue.process && issue.process.name}
            </p>

            <TagsDisplay tags={issue.tags} />

            <div>
                <p className={styles.SectionHeader}>
                    Descrição
		</p>

                <p className={styles.SectionContent}>
                    {issue.description}
                </p>
            </div>

            {issue.observations ? <div>
                <p className={styles.SectionHeader}>
                    Observações
		</p>
                <p className={styles.SectionContent}>
                    {issue.observations}
                </p>
            </div> : ''}

            <p className={styles.Creation}>
                Criado por <b>{issue.user?.name}</b> em {issue.createdAt && <FormatDate date={issue.createdAt} format={humanizedDate} />}
            </p>
        </Grid>
    )
};

export default IssueDetails;
