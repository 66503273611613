import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { downloadRequest, mediaUrl } from '../../utils/Utils';
import styles from './UserDetails.module.scss';

const UserDetails = ({ process, onComplete, onEdit }: { process: any, onComplete?: () => any, onEdit?: () => any }) => {

    const history = useHistory()

    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.Wrapper}>

            <Grid container justify="space-between" alignItems="center" className={styles.Actions}>
                <div>
                    <span className={styles.State}>{process.state}</span>
                </div>
                <Box>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => alert('not implemented yet')}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={onComplete}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Grid>

            <p className={styles.Name}>
                {process.name}
            </p>
            <p className={styles.Process}>
                code: <b>p{process.code}</b>
            </p>


            <div>
                <p className={styles.SectionHeader}>
                    Descrição
		</p>

                <p className={styles.SectionContent}>
                    {process.description}
                </p>
            </div>

            {process.diagrams.length > 0 ? <div>
                <p className={styles.SectionHeader}>
                    Diagrams
		</p>
                <p className={styles.SectionContent}>

                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>

                                {process.diagrams.map((diagram: any) => (
                                    <TableRow>
                                        <TableCell>
                                            version #{diagram.version}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => history.push(`/viewer/${process.code}/${diagram.version}`)}>
                                                <Tooltip title="view process diagram">
                                                    <LaunchIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => downloadRequest(mediaUrl(diagram.file), `${process.name}_diagram_v_${diagram.version}_.bpmn`)}>
                                                <Tooltip title="download process diagram">
                                                    <CloudDownloadIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => window.open('https://demo.bpmn.io/')}>
                                                <Tooltip title="edit process diagram">
                                                    <EditIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </p>
            </div> : ''
            }

        </Grid >
    )
};


export default UserDetails;
