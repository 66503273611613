import React from 'react';
// import {useQuery} from '@apollo/react-hooks';
// import {ISSUES} from '../../services/Queries';
// import Loading from '../Loading/Loading';
// import {useModal, useGlobalFab, useGuestRedirection} from '../../services/Hooks';
import {useGuestRedirection} from '../../services/Hooks';
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import FormatDate from '../FormatDate/FormatDate';
// import CustomModal from '../CustomModal/CustomModal';
// import CreateIssue from '../CreateIssue/CreateIssue';
// import styles from './Profile.module.scss';
// import Tooltip from '@material-ui/core/Tooltip';

const Profile = () => {

    useGuestRedirection();

    return <div>profile</div>;
};

export default Profile;
