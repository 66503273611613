import { capitalize } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Issue } from '../generated/graphql';
import { Config } from '../services/Config';

export const errorParser = (response: any) => {

    let errors: any = {}

    const key = Object.keys(response.data)[0];
    const e = response.data[key].errors;

    if (e) {
        e.map((error: any) => errors[error.field] = error.message[0]);
    }

    return errors;
}

/** returns an absolute url to a media resource given the filename */
export const mediaUrl = (rel: string) => (
    Config.MEDIA_URL + rel
)

/** applies text transformations, capitalize, allcaps */
export const transformer = (inputStr: string, transform: string) => {
    return transform === 'allcaps' ? inputStr.toUpperCase() : capitalize(inputStr)
}

/** manipulates issue states*/
export const issueStates = (state: Maybe<string>, transform: string = 'allcaps', language: string = 'pt') => {

    if (!state) return null

    const states: any = {
        CREATED: {
            pt: 'criada',
            en: 'created'
        },
        PENDING: {
            pt: 'pendente',
            en: 'pending'
        },
        RESOLVED: {
            pt: 'resolvida',
            en: 'resolsed'
        },
        PROGRESS: {
            pt: 'progresso',
            en: 'progress'
        },
        CANCELED: {
            pt: 'cancelada',
            en: 'canceled'
        }
    }

    const stateObj: any = states[state]
    const output: string = stateObj[language]

    return transform ? transformer(output, transform) : output;
}

/** manipulates issue natures */
export const issueNatures = (key: Maybe<string>, transform: string = 'allcaps', language: string = 'pt') => {

    if (!key) return null

    const options: any = {
        OPPORTUNITY: {
            pt: 'oportunidade',
            en: 'opportunity'
        },
        OCCURRENCE: {
            pt: 'ocorrência',
            en: 'occurrence'
        }
    }

    const obj: any = options[key]
    const output: string = obj[language]

    return transform ? transformer(output, transform) : output;
}

/** returns a string with all issue tags separated by the separator and alfabetically sorted*/
export const joinTags = (issue: Maybe<Issue>, separator: string = ', ') => {

    if (!issue || issue?.tags?.length === 0) {
        return 'sem etiquetas'
    }

    let tags = issue.tags?.map((t: any) => t.name)
    tags = tags?.sort()

    return 'etiquetas: ' + tags?.join(separator)
}

/**
* starts download of file from the frontend
* @param url - the url of file to download
* @param filename - the name of the file
*
* @example downloadRequest(url, 'folhasala.pdf')
*/
export const downloadRequest = (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function() {
        const type = xhr.getResponseHeader("content-type");
        const arrayBufferView = new Uint8Array(this.response);
        // @ts-ignore
        const blob = new Blob([arrayBufferView], { type });
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
    };
    xhr.send();
}

export const fetchOrganization = (): string | null => {
    return localStorage.getItem('auth.organization')
}

export const isCurrentOrganization = (organizationId?: string) => {
    return fetchOrganization() === organizationId
}

export const fetchUser = (): string | null => {
    return "" + localStorage.getItem('auth.user')
}


/**
* truncates a string at the required length
*
* @param str - the string to truncate
* @param chars - the length at which the string will be truncated (will not take in account the ellipsis)
* @param ellipsis - apply ellipsis to the truncated string (the returned string will be longer than chars value)
*/
export const truncateString = (str: string, chars: number, ellipsis?: boolean): string => {
    if (str.length <= chars) {
        return str
    }
    const sliced = str.slice(0, chars)
    return ellipsis ? sliced + '...' : sliced
}
