import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Upload: any;
  JSONObject: any;
};




export enum ExportScope {
  Process = 'PROCESS',
  Organization = 'ORGANIZATION'
}

export enum ExportState {
  Requested = 'REQUESTED',
  Fulfilled = 'FULFILLED'
}

export enum IssueNature {
  Occurrence = 'OCCURRENCE',
  Opportunity = 'OPPORTUNITY'
}

export enum IssueState {
  Created = 'CREATED',
  Pending = 'PENDING',
  Canceled = 'CANCELED',
  Progress = 'PROGRESS',
  Resolved = 'RESOLVED'
}

export enum NotificationState {
  Created = 'CREATED',
  Shown = 'SHOWN',
  Read = 'READ'
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/**
 * loosely based on BPMM maturity model but applied to a process
 * and not an organization see:
 * https://www.effic.be/en/business-process-maturity-models/
 */
export enum ProcessState {
  Initial = 'INITIAL',
  Predictable = 'PREDICTABLE',
  Optimized = 'OPTIMIZED'
}

export enum TicketState {
  New = 'NEW',
  Progress = 'PROGRESS',
  Closed = 'CLOSED'
}

export enum TopicState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum UserState {
  Invited = 'INVITED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CreateContactInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateDiagramInput = {
  process: Scalars['ID'];
  file: Scalars['Upload'];
  version: Scalars['Int'];
  observations?: Maybe<Scalars['String']>;
};

export type CreateExportInput = {
  process?: Maybe<Scalars['ID']>;
  organization?: Maybe<Scalars['ID']>;
  scope: ExportScope;
};

export type CreateIssueInput = {
  name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  nature: IssueNature;
  state?: Maybe<IssueState>;
  issueDate?: Maybe<Scalars['String']>;
  process: Scalars['ID'];
  priority?: Maybe<Scalars['Int']>;
};

export type CreateIssueTagInput = {
  issue: Scalars['ID'];
  tag: Scalars['ID'];
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
};

export type CreateOrganizationUserInput = {
  organization: Scalars['ID'];
  user: CreateUserInput;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type CreateOrganizationWithAdminInput = {
  organization: CreateOrganizationInput;
  user: CreateUserInput;
};

export type CreateProcessInput = {
  name: Scalars['String'];
  organization: Scalars['ID'];
};

export type CreateProcessUserInput = {
  process: Scalars['ID'];
  user: Scalars['ID'];
  isOwner?: Maybe<Scalars['Boolean']>;
};

export type CreateProcessWithOwnerInput = {
  name: Scalars['String'];
  organization: Scalars['ID'];
  user?: Maybe<Scalars['ID']>;
};

export type CreateTagInput = {
  name: Scalars['String'];
  organization: Scalars['ID'];
};

export type ExecuteTaskStepInput = {
  func?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['String']>;
};

export type CreateTicketInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type CreateUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type OrderInput = {
  field: Scalars['String'];
  direction: OrderDirection;
};

export type RemoveIssueInput = {
  issue: Scalars['ID'];
};

export type RemoveIssueTagInput = {
  issue: Scalars['ID'];
  tag: Scalars['ID'];
};

export type RemoveOrganizationUserInput = {
  organizationUser: Scalars['ID'];
};

export type RemoveProcessUserInput = {
  processUser: Scalars['ID'];
};

export type UpdateIssueInput = {
  issue: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nature?: Maybe<IssueNature>;
  state?: Maybe<IssueState>;
  issueDate?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

export type UpdateNotificationInput = {
  notification: Scalars['ID'];
  state?: Maybe<NotificationState>;
};

export type UpdateOrganizationUserInput = {
  organizationUser: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
};

export type UpdateProcessInput = {
  process: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state?: Maybe<ProcessState>;
};

export type UpdateProcessUserInput = {
  processUser: Scalars['ID'];
  isOwner: Scalars['Boolean'];
};

export type ActivatePayload = {
  __typename?: 'ActivatePayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  contact?: Maybe<Contact>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateDiagramPayload = {
  __typename?: 'CreateDiagramPayload';
  diagram?: Maybe<Diagram>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateExportPayload = {
  __typename?: 'CreateExportPayload';
  export?: Maybe<Export>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateIssuePayload = {
  __typename?: 'CreateIssuePayload';
  issue?: Maybe<Issue>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateIssueTagPayload = {
  __typename?: 'CreateIssueTagPayload';
  issue?: Maybe<Issue>;
  tag?: Maybe<Tag>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  organization?: Maybe<Organization>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateOrganizationUserPayload = {
  __typename?: 'CreateOrganizationUserPayload';
  organizationUser?: Maybe<OrganizationUser>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateOrganizationWithAdminPayload = {
  __typename?: 'CreateOrganizationWithAdminPayload';
  organizationUser?: Maybe<OrganizationUser>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateProcessPayload = {
  __typename?: 'CreateProcessPayload';
  process?: Maybe<Process>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateProcessUserPayload = {
  __typename?: 'CreateProcessUserPayload';
  processUser?: Maybe<ProcessUser>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  tag?: Maybe<Tag>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateTicketPayload = {
  __typename?: 'CreateTicketPayload';
  ticket?: Maybe<Ticket>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type MePayload = {
  __typename?: 'MePayload';
  user?: Maybe<User>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveIssuePayload = {
  __typename?: 'RemoveIssuePayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveIssueTagPayload = {
  __typename?: 'RemoveIssueTagPayload';
  issue: Issue;
  tag: Tag;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveOrganizationUserPayload = {
  __typename?: 'RemoveOrganizationUserPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveProcessUserPayload = {
  __typename?: 'RemoveProcessUserPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type TaskStepPayload = {
  __typename?: 'TaskStepPayload';
  func?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['String']>;
};

export type UpdateIssuePayload = {
  __typename?: 'UpdateIssuePayload';
  issue?: Maybe<Issue>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateNotificationPayload = {
  __typename?: 'UpdateNotificationPayload';
  notification?: Maybe<Notification>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateOrganizationUserPayload = {
  __typename?: 'UpdateOrganizationUserPayload';
  organizationUser?: Maybe<OrganizationUser>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateProcessPayload = {
  __typename?: 'UpdateProcessPayload';
  process?: Maybe<Process>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateProcessUserPayload = {
  __typename?: 'UpdateProcessUserPayload';
  processUser?: Maybe<ProcessUser>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Contacts = {
  __typename?: 'Contacts';
  results?: Maybe<Array<Maybe<Contact>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Diagram = {
  __typename?: 'Diagram';
  id: Scalars['ID'];
  process: Process;
  version: Scalars['Int'];
  file: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Export = {
  __typename?: 'Export';
  id: Scalars['ID'];
  user: User;
  process?: Maybe<Process>;
  scope: ExportScope;
  state: ExportState;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Exports = {
  __typename?: 'Exports';
  results?: Maybe<Array<Maybe<Export>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Features = {
  __typename?: 'Features';
  results?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['ID'];
  sequence: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  nature: IssueNature;
  state?: Maybe<IssueState>;
  priority: Scalars['Int'];
  issueDate?: Maybe<Scalars['String']>;
  process?: Maybe<Process>;
  user?: Maybe<User>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Issues = {
  __typename?: 'Issues';
  results?: Maybe<Array<Maybe<Issue>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  state: NotificationState;
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Notifications = {
  __typename?: 'Notifications';
  results?: Maybe<Array<Maybe<Notification>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Organizations = {
  __typename?: 'Organizations';
  results?: Maybe<Array<Maybe<Organization>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  id: Scalars['ID'];
  organization: Organization;
  user: User;
  isAdmin: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationUsers = {
  __typename?: 'OrganizationUsers';
  results?: Maybe<Array<Maybe<OrganizationUser>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Process = {
  __typename?: 'Process';
  id: Scalars['ID'];
  diagrams?: Maybe<Array<Maybe<Diagram>>>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  state: ProcessState;
  recurrence?: Maybe<Scalars['String']>;
  automation?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  tasks?: Maybe<Scalars['JSONObject']>;
  processUsers?: Maybe<Array<Maybe<ProcessUser>>>;
  organization?: Maybe<Organization>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Processes = {
  __typename?: 'Processes';
  results?: Maybe<Array<Maybe<Process>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ProcessUser = {
  __typename?: 'ProcessUser';
  id: Scalars['ID'];
  process: Process;
  user: User;
  isOwner: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ProcessUsers = {
  __typename?: 'ProcessUsers';
  results?: Maybe<Array<Maybe<ProcessUser>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Tags = {
  __typename?: 'Tags';
  results?: Maybe<Array<Maybe<Tag>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Ticket = {
  __typename?: 'Ticket';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  state: TicketState;
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Tickets = {
  __typename?: 'Tickets';
  results?: Maybe<Array<Maybe<Ticket>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  state: TopicState;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Topics = {
  __typename?: 'Topics';
  results?: Maybe<Array<Maybe<Topic>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  state: UserState;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  isEmailVerified: Scalars['Boolean'];
  defaultOrganization?: Maybe<Organization>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Users = {
  __typename?: 'Users';
  results?: Maybe<Array<Maybe<User>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Query = {
  __typename?: 'Query';
  contacts?: Maybe<Contacts>;
  exports?: Maybe<Exports>;
  features?: Maybe<Features>;
  issue?: Maybe<Issue>;
  issues?: Maybe<Issues>;
  me?: Maybe<MePayload>;
  notifications?: Maybe<Notifications>;
  organizations?: Maybe<Organizations>;
  organizationUsers?: Maybe<OrganizationUsers>;
  process?: Maybe<Process>;
  processes?: Maybe<Processes>;
  processUsers?: Maybe<ProcessUsers>;
  status: Scalars['String'];
  tags?: Maybe<Tags>;
  ticket?: Maybe<Ticket>;
  tickets?: Maybe<Tickets>;
  topic?: Maybe<Topic>;
  topics?: Maybe<Topics>;
  users?: Maybe<Users>;
  getIssues?: Maybe<Issues>;
};


export type QueryContactsArgs = {
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryFeaturesArgs = {
  organization?: Maybe<Scalars['ID']>;
};


export type QueryIssueArgs = {
  id: Scalars['ID'];
};


export type QueryIssuesArgs = {
  state?: Maybe<IssueState>;
  process?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  organization?: Maybe<Scalars['ID']>;
};


export type QueryNotificationsArgs = {
  state?: Maybe<NotificationState>;
};


export type QueryOrganizationUsersArgs = {
  organization?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryProcessArgs = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryProcessesArgs = {
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  organization?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryTicketsArgs = {
  state?: Maybe<TicketState>;
  search?: Maybe<Scalars['String']>;
};


export type QueryTopicArgs = {
  id: Scalars['ID'];
};


export type QueryTopicsArgs = {
  state?: Maybe<TopicState>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetIssuesArgs = {
  state?: Maybe<IssueState>;
  priority?: Maybe<Scalars['Int']>;
  process?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['ID']>;
  organization?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activate?: Maybe<ActivatePayload>;
  createContact?: Maybe<CreateContactPayload>;
  createDiagram?: Maybe<CreateDiagramPayload>;
  createExport?: Maybe<CreateExportPayload>;
  createIssue?: Maybe<CreateIssuePayload>;
  createIssueTag?: Maybe<CreateIssueTagPayload>;
  createOrganization?: Maybe<CreateOrganizationPayload>;
  createOrganizationUser?: Maybe<CreateOrganizationUserPayload>;
  createOrganizationWithAdmin?: Maybe<CreateOrganizationWithAdminPayload>;
  createProcess?: Maybe<CreateProcessPayload>;
  createProcessUser?: Maybe<CreateProcessUserPayload>;
  createProcessWithOwner?: Maybe<CreateProcessPayload>;
  createTag?: Maybe<CreateTagPayload>;
  createTicket?: Maybe<CreateTicketPayload>;
  createUser?: Maybe<AuthPayload>;
  executeTaskStep?: Maybe<Scalars['Boolean']>;
  login?: Maybe<AuthPayload>;
  removeIssue?: Maybe<RemoveIssuePayload>;
  removeIssueTag?: Maybe<RemoveIssueTagPayload>;
  removeOrganizationUser?: Maybe<RemoveOrganizationUserPayload>;
  removeProcessUser?: Maybe<RemoveProcessUserPayload>;
  updateIssue?: Maybe<UpdateIssuePayload>;
  updateNotification?: Maybe<UpdateNotificationPayload>;
  updateOrganizationUser?: Maybe<UpdateOrganizationUserPayload>;
  updateProcess?: Maybe<UpdateProcessPayload>;
  updateProcessUser?: Maybe<UpdateProcessUserPayload>;
};


export type MutationActivateArgs = {
  token: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateContactArgs = {
  data?: Maybe<CreateContactInput>;
};


export type MutationCreateDiagramArgs = {
  data?: Maybe<CreateDiagramInput>;
};


export type MutationCreateExportArgs = {
  data?: Maybe<CreateExportInput>;
};


export type MutationCreateIssueArgs = {
  data?: Maybe<CreateIssueInput>;
};


export type MutationCreateIssueTagArgs = {
  data?: Maybe<CreateIssueTagInput>;
};


export type MutationCreateOrganizationArgs = {
  data?: Maybe<CreateOrganizationInput>;
};


export type MutationCreateOrganizationUserArgs = {
  data?: Maybe<CreateOrganizationUserInput>;
};


export type MutationCreateOrganizationWithAdminArgs = {
  data?: Maybe<CreateOrganizationWithAdminInput>;
};


export type MutationCreateProcessArgs = {
  data?: Maybe<CreateProcessInput>;
};


export type MutationCreateProcessUserArgs = {
  data?: Maybe<CreateProcessUserInput>;
};


export type MutationCreateProcessWithOwnerArgs = {
  data?: Maybe<CreateProcessWithOwnerInput>;
};


export type MutationCreateTagArgs = {
  data?: Maybe<CreateTagInput>;
};


export type MutationCreateTicketArgs = {
  data?: Maybe<CreateTicketInput>;
};


export type MutationCreateUserArgs = {
  data?: Maybe<CreateUserInput>;
};


export type MutationExecuteTaskStepArgs = {
  data?: Maybe<ExecuteTaskStepInput>;
};


export type MutationLoginArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationRemoveIssueArgs = {
  data?: Maybe<RemoveIssueInput>;
};


export type MutationRemoveIssueTagArgs = {
  data?: Maybe<RemoveIssueTagInput>;
};


export type MutationRemoveOrganizationUserArgs = {
  data?: Maybe<RemoveOrganizationUserInput>;
};


export type MutationRemoveProcessUserArgs = {
  data?: Maybe<RemoveProcessUserInput>;
};


export type MutationUpdateIssueArgs = {
  data?: Maybe<UpdateIssueInput>;
};


export type MutationUpdateNotificationArgs = {
  data?: Maybe<UpdateNotificationInput>;
};


export type MutationUpdateOrganizationUserArgs = {
  data?: Maybe<UpdateOrganizationUserInput>;
};


export type MutationUpdateProcessArgs = {
  data?: Maybe<UpdateProcessInput>;
};


export type MutationUpdateProcessUserArgs = {
  data?: Maybe<UpdateProcessUserInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  taskStep?: Maybe<TaskStepPayload>;
};

export type ProcessPlusFeaturesQueryVariables = Exact<{
  organization?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type ProcessPlusFeaturesQuery = (
  { __typename?: 'Query' }
  & { features?: Maybe<(
    { __typename?: 'Features' }
    & Pick<Features, 'count' | 'results'>
  )>, process?: Maybe<(
    { __typename?: 'Process' }
    & Pick<Process, 'id' | 'name' | 'code' | 'description' | 'state'>
    & { diagrams?: Maybe<Array<Maybe<(
      { __typename?: 'Diagram' }
      & Pick<Diagram, 'id' | 'file' | 'version'>
    )>>>, processUsers?: Maybe<Array<Maybe<(
      { __typename?: 'ProcessUser' }
      & Pick<ProcessUser, 'id' | 'isOwner'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      ) }
    )>>> }
  )> }
);

export type FeaturesQueryVariables = Exact<{
  organization?: Maybe<Scalars['ID']>;
}>;


export type FeaturesQuery = (
  { __typename?: 'Query' }
  & { features?: Maybe<(
    { __typename?: 'Features' }
    & Pick<Features, 'count' | 'results'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'MePayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'isEmailVerified'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type IssuesQueryVariables = Exact<{
  state?: Maybe<IssueState>;
  process?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  organization?: Maybe<Scalars['ID']>;
}>;


export type IssuesQuery = (
  { __typename?: 'Query' }
  & { issues?: Maybe<(
    { __typename?: 'Issues' }
    & Pick<Issues, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'name' | 'description' | 'nature' | 'state' | 'observations' | 'issueDate' | 'priority' | 'sequence' | 'createdAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )>, process?: Maybe<(
        { __typename?: 'Process' }
        & Pick<Process, 'id' | 'name'>
      )>, tags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>>> }
    )>>> }
  )> }
);

export type GetIssuesQueryVariables = Exact<{
  state?: Maybe<IssueState>;
  process?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  organization?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['ID']>;
}>;


export type GetIssuesQuery = (
  { __typename?: 'Query' }
  & { getIssues?: Maybe<(
    { __typename?: 'Issues' }
    & Pick<Issues, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'name' | 'description' | 'nature' | 'state' | 'observations' | 'issueDate' | 'priority' | 'sequence' | 'createdAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )>, process?: Maybe<(
        { __typename?: 'Process' }
        & Pick<Process, 'id' | 'name'>
      )>, tags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>>> }
    )>>> }
  )> }
);

export type ProcessQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type ProcessQuery = (
  { __typename?: 'Query' }
  & { process?: Maybe<(
    { __typename?: 'Process' }
    & Pick<Process, 'id' | 'name' | 'code' | 'description' | 'state' | 'tasks'>
    & { diagrams?: Maybe<Array<Maybe<(
      { __typename?: 'Diagram' }
      & Pick<Diagram, 'id' | 'file' | 'version'>
    )>>>, processUsers?: Maybe<Array<Maybe<(
      { __typename?: 'ProcessUser' }
      & Pick<ProcessUser, 'id' | 'isOwner'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      ) }
    )>>> }
  )> }
);

export type ProcessesQueryVariables = Exact<{
  order?: Maybe<OrderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['ID']>;
}>;


export type ProcessesQuery = (
  { __typename?: 'Query' }
  & { processes?: Maybe<(
    { __typename?: 'Processes' }
    & Pick<Processes, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Process' }
      & Pick<Process, 'id' | 'name' | 'code' | 'description' | 'state'>
      & { diagrams?: Maybe<Array<Maybe<(
        { __typename?: 'Diagram' }
        & Pick<Diagram, 'id' | 'file' | 'version'>
      )>>> }
    )>>> }
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'Users' }
    & Pick<Users, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>>> }
  )> }
);

export type StatusQueryVariables = Exact<{ [key: string]: never; }>;


export type StatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'status'>
);

export type TagsQueryVariables = Exact<{
  organization?: Maybe<Scalars['ID']>;
  order?: Maybe<OrderInput>;
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags?: Maybe<(
    { __typename?: 'Tags' }
    & Pick<Tags, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type OrganizationUsersQueryVariables = Exact<{
  organization?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderInput>;
}>;


export type OrganizationUsersQuery = (
  { __typename?: 'Query' }
  & { organizationUsers?: Maybe<(
    { __typename?: 'OrganizationUsers' }
    & Pick<OrganizationUsers, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'createdAt' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email' | 'state'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      ) }
    )>>> }
  )> }
);

export type CreateDiagramMutationVariables = Exact<{
  data?: Maybe<CreateDiagramInput>;
}>;


export type CreateDiagramMutation = (
  { __typename?: 'Mutation' }
  & { createDiagram?: Maybe<(
    { __typename?: 'CreateDiagramPayload' }
    & { diagram?: Maybe<(
      { __typename?: 'Diagram' }
      & Pick<Diagram, 'id' | 'version' | 'file'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type CreateProcessMutationVariables = Exact<{
  data?: Maybe<CreateProcessInput>;
}>;


export type CreateProcessMutation = (
  { __typename?: 'Mutation' }
  & { createProcess?: Maybe<(
    { __typename?: 'CreateProcessPayload' }
    & { process?: Maybe<(
      { __typename?: 'Process' }
      & Pick<Process, 'id' | 'name'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type CreateProcessWithOwnerMutationVariables = Exact<{
  data?: Maybe<CreateProcessWithOwnerInput>;
}>;


export type CreateProcessWithOwnerMutation = (
  { __typename?: 'Mutation' }
  & { createProcessWithOwner?: Maybe<(
    { __typename?: 'CreateProcessPayload' }
    & { process?: Maybe<(
      { __typename?: 'Process' }
      & Pick<Process, 'id' | 'name'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type CreateIssueMutationVariables = Exact<{
  data?: Maybe<CreateIssueInput>;
}>;


export type CreateIssueMutation = (
  { __typename?: 'Mutation' }
  & { createIssue?: Maybe<(
    { __typename?: 'CreateIssuePayload' }
    & { issue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'description'>
    )> }
  )> }
);

export type CreateTagMutationVariables = Exact<{
  data?: Maybe<CreateTagInput>;
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag?: Maybe<(
    { __typename?: 'CreateTagPayload' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type ExecuteTaskStepMutationVariables = Exact<{
  data?: Maybe<ExecuteTaskStepInput>;
}>;


export type ExecuteTaskStepMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'executeTaskStep'>
);

export type UpdateIssueMutationVariables = Exact<{
  data?: Maybe<UpdateIssueInput>;
}>;


export type UpdateIssueMutation = (
  { __typename?: 'Mutation' }
  & { updateIssue?: Maybe<(
    { __typename?: 'UpdateIssuePayload' }
    & { issue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'description' | 'state' | 'nature' | 'observations'>
    )> }
  )> }
);

export type UpdateProcessMutationVariables = Exact<{
  data?: Maybe<UpdateProcessInput>;
}>;


export type UpdateProcessMutation = (
  { __typename?: 'Mutation' }
  & { updateProcess?: Maybe<(
    { __typename?: 'UpdateProcessPayload' }
    & { process?: Maybe<(
      { __typename?: 'Process' }
      & Pick<Process, 'id' | 'description' | 'state' | 'code'>
    )> }
  )> }
);

export type UpdateProcessUserMutationVariables = Exact<{
  data?: Maybe<UpdateProcessUserInput>;
}>;


export type UpdateProcessUserMutation = (
  { __typename?: 'Mutation' }
  & { updateProcessUser?: Maybe<(
    { __typename?: 'UpdateProcessUserPayload' }
    & { processUser?: Maybe<(
      { __typename?: 'ProcessUser' }
      & Pick<ProcessUser, 'isOwner'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type CreateIssueTagMutationVariables = Exact<{
  data?: Maybe<CreateIssueTagInput>;
}>;


export type CreateIssueTagMutation = (
  { __typename?: 'Mutation' }
  & { createIssueTag?: Maybe<(
    { __typename?: 'CreateIssueTagPayload' }
    & { issue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id'>
    )>, tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )> }
);

export type RemoveIssueTagMutationVariables = Exact<{
  data?: Maybe<RemoveIssueTagInput>;
}>;


export type RemoveIssueTagMutation = (
  { __typename?: 'Mutation' }
  & { removeIssueTag?: Maybe<(
    { __typename?: 'RemoveIssueTagPayload' }
    & { issue: (
      { __typename?: 'Issue' }
      & Pick<Issue, 'id'>
    ), tag: (
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    ) }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  data?: Maybe<CreateUserInput>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'AuthPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type LoginMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'isSuperuser'>
      & { defaultOrganization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      )> }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type CreateContactMutationVariables = Exact<{
  data?: Maybe<CreateContactInput>;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact?: Maybe<(
    { __typename?: 'CreateContactPayload' }
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'email' | 'name'>
    )> }
  )> }
);

export type CreateOrganizationWithAdminMutationVariables = Exact<{
  data?: Maybe<CreateOrganizationWithAdminInput>;
}>;


export type CreateOrganizationWithAdminMutation = (
  { __typename?: 'Mutation' }
  & { createOrganizationWithAdmin?: Maybe<(
    { __typename?: 'CreateOrganizationWithAdminPayload' }
    & { organizationUser?: Maybe<(
      { __typename?: 'OrganizationUser' }
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type CreateOrganizationUserMutationVariables = Exact<{
  data?: Maybe<CreateOrganizationUserInput>;
}>;


export type CreateOrganizationUserMutation = (
  { __typename?: 'Mutation' }
  & { createOrganizationUser?: Maybe<(
    { __typename?: 'CreateOrganizationUserPayload' }
    & { organizationUser?: Maybe<(
      { __typename?: 'OrganizationUser' }
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type RemoveProcessUserMutationVariables = Exact<{
  data?: Maybe<RemoveProcessUserInput>;
}>;


export type RemoveProcessUserMutation = (
  { __typename?: 'Mutation' }
  & { removeProcessUser?: Maybe<(
    { __typename?: 'RemoveProcessUserPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type ActivateMutationVariables = Exact<{
  token: Scalars['String'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type ActivateMutation = (
  { __typename?: 'Mutation' }
  & { activate?: Maybe<(
    { __typename?: 'ActivatePayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);


export const ProcessPlusFeaturesDocument = gql`
    query processPlusFeatures($organization: ID, $code: String, $id: ID) {
  features(organization: $organization) {
    count
    results
  }
  process(code: $code, id: $id) {
    id
    name
    code
    description
    state
    diagrams {
      id
      file
      version
    }
    processUsers {
      id
      isOwner
      user {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProcessPlusFeaturesQuery__
 *
 * To run a query within a React component, call `useProcessPlusFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessPlusFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessPlusFeaturesQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *      code: // value for 'code'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessPlusFeaturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcessPlusFeaturesQuery, ProcessPlusFeaturesQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcessPlusFeaturesQuery, ProcessPlusFeaturesQueryVariables>(ProcessPlusFeaturesDocument, baseOptions);
      }
export function useProcessPlusFeaturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcessPlusFeaturesQuery, ProcessPlusFeaturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcessPlusFeaturesQuery, ProcessPlusFeaturesQueryVariables>(ProcessPlusFeaturesDocument, baseOptions);
        }
export type ProcessPlusFeaturesQueryHookResult = ReturnType<typeof useProcessPlusFeaturesQuery>;
export type ProcessPlusFeaturesLazyQueryHookResult = ReturnType<typeof useProcessPlusFeaturesLazyQuery>;
export type ProcessPlusFeaturesQueryResult = ApolloReactCommon.QueryResult<ProcessPlusFeaturesQuery, ProcessPlusFeaturesQueryVariables>;
export const FeaturesDocument = gql`
    query features($organization: ID) {
  features(organization: $organization) {
    count
    results
  }
}
    `;

/**
 * __useFeaturesQuery__
 *
 * To run a query within a React component, call `useFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturesQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useFeaturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
        return ApolloReactHooks.useQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, baseOptions);
      }
export function useFeaturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, baseOptions);
        }
export type FeaturesQueryHookResult = ReturnType<typeof useFeaturesQuery>;
export type FeaturesLazyQueryHookResult = ReturnType<typeof useFeaturesLazyQuery>;
export type FeaturesQueryResult = ApolloReactCommon.QueryResult<FeaturesQuery, FeaturesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    user {
      id
      name
      email
      isEmailVerified
    }
    errors {
      field
      message
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const IssuesDocument = gql`
    query issues($state: IssueState, $process: ID, $user: ID, $order: OrderInput, $page: Int, $search: String, $priority: Int, $organization: ID) {
  issues(state: $state, process: $process, user: $user, order: $order, page: $page, search: $search, priority: $priority, organization: $organization) {
    count
    results {
      id
      name
      description
      nature
      state
      observations
      issueDate
      priority
      sequence
      user {
        id
        name
      }
      process {
        id
        name
      }
      tags {
        id
        name
      }
      createdAt
    }
  }
}
    `;

/**
 * __useIssuesQuery__
 *
 * To run a query within a React component, call `useIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuesQuery({
 *   variables: {
 *      state: // value for 'state'
 *      process: // value for 'process'
 *      user: // value for 'user'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      priority: // value for 'priority'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useIssuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IssuesQuery, IssuesQueryVariables>) {
        return ApolloReactHooks.useQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, baseOptions);
      }
export function useIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IssuesQuery, IssuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, baseOptions);
        }
export type IssuesQueryHookResult = ReturnType<typeof useIssuesQuery>;
export type IssuesLazyQueryHookResult = ReturnType<typeof useIssuesLazyQuery>;
export type IssuesQueryResult = ApolloReactCommon.QueryResult<IssuesQuery, IssuesQueryVariables>;
export const GetIssuesDocument = gql`
    query getIssues($state: IssueState, $process: ID, $user: ID, $order: OrderInput, $page: Int, $search: String, $priority: Int, $organization: ID, $tag: ID) {
  getIssues(state: $state, process: $process, user: $user, order: $order, page: $page, search: $search, priority: $priority, organization: $organization, tag: $tag) {
    count
    results {
      id
      name
      description
      nature
      state
      observations
      issueDate
      priority
      sequence
      user {
        id
        name
      }
      process {
        id
        name
      }
      tags {
        id
        name
      }
      createdAt
    }
  }
}
    `;

/**
 * __useGetIssuesQuery__
 *
 * To run a query within a React component, call `useGetIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesQuery({
 *   variables: {
 *      state: // value for 'state'
 *      process: // value for 'process'
 *      user: // value for 'user'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      priority: // value for 'priority'
 *      organization: // value for 'organization'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGetIssuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, baseOptions);
      }
export function useGetIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, baseOptions);
        }
export type GetIssuesQueryHookResult = ReturnType<typeof useGetIssuesQuery>;
export type GetIssuesLazyQueryHookResult = ReturnType<typeof useGetIssuesLazyQuery>;
export type GetIssuesQueryResult = ApolloReactCommon.QueryResult<GetIssuesQuery, GetIssuesQueryVariables>;
export const ProcessDocument = gql`
    query process($code: String, $id: ID) {
  process(code: $code, id: $id) {
    id
    name
    code
    description
    state
    diagrams {
      id
      file
      version
    }
    processUsers {
      id
      isOwner
      user {
        id
        name
      }
    }
    tasks
  }
}
    `;

/**
 * __useProcessQuery__
 *
 * To run a query within a React component, call `useProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessQuery({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcessQuery, ProcessQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcessQuery, ProcessQueryVariables>(ProcessDocument, baseOptions);
      }
export function useProcessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcessQuery, ProcessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcessQuery, ProcessQueryVariables>(ProcessDocument, baseOptions);
        }
export type ProcessQueryHookResult = ReturnType<typeof useProcessQuery>;
export type ProcessLazyQueryHookResult = ReturnType<typeof useProcessLazyQuery>;
export type ProcessQueryResult = ApolloReactCommon.QueryResult<ProcessQuery, ProcessQueryVariables>;
export const ProcessesDocument = gql`
    query processes($order: OrderInput, $page: Int, $pageSize: Int, $search: String, $organization: ID) {
  processes(order: $order, page: $page, pageSize: $pageSize, search: $search, organization: $organization) {
    count
    results {
      id
      name
      code
      description
      state
      diagrams {
        id
        file
        version
      }
    }
  }
}
    `;

/**
 * __useProcessesQuery__
 *
 * To run a query within a React component, call `useProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessesQuery({
 *   variables: {
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useProcessesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcessesQuery, ProcessesQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcessesQuery, ProcessesQueryVariables>(ProcessesDocument, baseOptions);
      }
export function useProcessesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcessesQuery, ProcessesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcessesQuery, ProcessesQueryVariables>(ProcessesDocument, baseOptions);
        }
export type ProcessesQueryHookResult = ReturnType<typeof useProcessesQuery>;
export type ProcessesLazyQueryHookResult = ReturnType<typeof useProcessesLazyQuery>;
export type ProcessesQueryResult = ApolloReactCommon.QueryResult<ProcessesQuery, ProcessesQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    count
    results {
      id
      name
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const StatusDocument = gql`
    query status {
  status
}
    `;

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatusQuery, StatusQueryVariables>) {
        return ApolloReactHooks.useQuery<StatusQuery, StatusQueryVariables>(StatusDocument, baseOptions);
      }
export function useStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatusQuery, StatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatusQuery, StatusQueryVariables>(StatusDocument, baseOptions);
        }
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>;
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>;
export type StatusQueryResult = ApolloReactCommon.QueryResult<StatusQuery, StatusQueryVariables>;
export const TagsDocument = gql`
    query tags($organization: ID, $order: OrderInput, $search: String, $page: Int, $pageSize: Int) {
  tags(organization: $organization, order: $order, search: $search, page: $page, pageSize: $pageSize) {
    count
    results {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *      order: // value for 'order'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return ApolloReactHooks.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = ApolloReactCommon.QueryResult<TagsQuery, TagsQueryVariables>;
export const OrganizationUsersDocument = gql`
    query organizationUsers($organization: ID, $user: ID, $search: String, $page: Int, $pageSize: Int, $order: OrderInput) {
  organizationUsers(organization: $organization, user: $user, search: $search, page: $page, pageSize: $pageSize, order: $order) {
    count
    results {
      id
      user {
        id
        name
        email
        state
      }
      organization {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *      user: // value for 'user'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrganizationUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, baseOptions);
      }
export function useOrganizationUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, baseOptions);
        }
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = ApolloReactCommon.QueryResult<OrganizationUsersQuery, OrganizationUsersQueryVariables>;
export const CreateDiagramDocument = gql`
    mutation createDiagram($data: CreateDiagramInput) {
  createDiagram(data: $data) {
    diagram {
      id
      version
      file
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateDiagramMutationFn = ApolloReactCommon.MutationFunction<CreateDiagramMutation, CreateDiagramMutationVariables>;

/**
 * __useCreateDiagramMutation__
 *
 * To run a mutation, you first call `useCreateDiagramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiagramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiagramMutation, { data, loading, error }] = useCreateDiagramMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDiagramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDiagramMutation, CreateDiagramMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDiagramMutation, CreateDiagramMutationVariables>(CreateDiagramDocument, baseOptions);
      }
export type CreateDiagramMutationHookResult = ReturnType<typeof useCreateDiagramMutation>;
export type CreateDiagramMutationResult = ApolloReactCommon.MutationResult<CreateDiagramMutation>;
export type CreateDiagramMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiagramMutation, CreateDiagramMutationVariables>;
export const CreateProcessDocument = gql`
    mutation createProcess($data: CreateProcessInput) {
  createProcess(data: $data) {
    process {
      id
      name
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateProcessMutationFn = ApolloReactCommon.MutationFunction<CreateProcessMutation, CreateProcessMutationVariables>;

/**
 * __useCreateProcessMutation__
 *
 * To run a mutation, you first call `useCreateProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessMutation, { data, loading, error }] = useCreateProcessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProcessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProcessMutation, CreateProcessMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProcessMutation, CreateProcessMutationVariables>(CreateProcessDocument, baseOptions);
      }
export type CreateProcessMutationHookResult = ReturnType<typeof useCreateProcessMutation>;
export type CreateProcessMutationResult = ApolloReactCommon.MutationResult<CreateProcessMutation>;
export type CreateProcessMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProcessMutation, CreateProcessMutationVariables>;
export const CreateProcessWithOwnerDocument = gql`
    mutation createProcessWithOwner($data: CreateProcessWithOwnerInput) {
  createProcessWithOwner(data: $data) {
    process {
      id
      name
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateProcessWithOwnerMutationFn = ApolloReactCommon.MutationFunction<CreateProcessWithOwnerMutation, CreateProcessWithOwnerMutationVariables>;

/**
 * __useCreateProcessWithOwnerMutation__
 *
 * To run a mutation, you first call `useCreateProcessWithOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessWithOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessWithOwnerMutation, { data, loading, error }] = useCreateProcessWithOwnerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProcessWithOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProcessWithOwnerMutation, CreateProcessWithOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProcessWithOwnerMutation, CreateProcessWithOwnerMutationVariables>(CreateProcessWithOwnerDocument, baseOptions);
      }
export type CreateProcessWithOwnerMutationHookResult = ReturnType<typeof useCreateProcessWithOwnerMutation>;
export type CreateProcessWithOwnerMutationResult = ApolloReactCommon.MutationResult<CreateProcessWithOwnerMutation>;
export type CreateProcessWithOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProcessWithOwnerMutation, CreateProcessWithOwnerMutationVariables>;
export const CreateIssueDocument = gql`
    mutation createIssue($data: CreateIssueInput) {
  createIssue(data: $data) {
    issue {
      id
      description
    }
  }
}
    `;
export type CreateIssueMutationFn = ApolloReactCommon.MutationFunction<CreateIssueMutation, CreateIssueMutationVariables>;

/**
 * __useCreateIssueMutation__
 *
 * To run a mutation, you first call `useCreateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueMutation, { data, loading, error }] = useCreateIssueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateIssueMutation, CreateIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateIssueMutation, CreateIssueMutationVariables>(CreateIssueDocument, baseOptions);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = ApolloReactCommon.MutationResult<CreateIssueMutation>;
export type CreateIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateIssueMutation, CreateIssueMutationVariables>;
export const CreateTagDocument = gql`
    mutation createTag($data: CreateTagInput) {
  createTag(data: $data) {
    tag {
      id
      name
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateTagMutationFn = ApolloReactCommon.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = ApolloReactCommon.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const ExecuteTaskStepDocument = gql`
    mutation executeTaskStep($data: ExecuteTaskStepInput) {
  executeTaskStep(data: $data)
}
    `;
export type ExecuteTaskStepMutationFn = ApolloReactCommon.MutationFunction<ExecuteTaskStepMutation, ExecuteTaskStepMutationVariables>;

/**
 * __useExecuteTaskStepMutation__
 *
 * To run a mutation, you first call `useExecuteTaskStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteTaskStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeTaskStepMutation, { data, loading, error }] = useExecuteTaskStepMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExecuteTaskStepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteTaskStepMutation, ExecuteTaskStepMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteTaskStepMutation, ExecuteTaskStepMutationVariables>(ExecuteTaskStepDocument, baseOptions);
      }
export type ExecuteTaskStepMutationHookResult = ReturnType<typeof useExecuteTaskStepMutation>;
export type ExecuteTaskStepMutationResult = ApolloReactCommon.MutationResult<ExecuteTaskStepMutation>;
export type ExecuteTaskStepMutationOptions = ApolloReactCommon.BaseMutationOptions<ExecuteTaskStepMutation, ExecuteTaskStepMutationVariables>;
export const UpdateIssueDocument = gql`
    mutation updateIssue($data: UpdateIssueInput) {
  updateIssue(data: $data) {
    issue {
      id
      description
      state
      nature
      observations
    }
  }
}
    `;
export type UpdateIssueMutationFn = ApolloReactCommon.MutationFunction<UpdateIssueMutation, UpdateIssueMutationVariables>;

/**
 * __useUpdateIssueMutation__
 *
 * To run a mutation, you first call `useUpdateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIssueMutation, { data, loading, error }] = useUpdateIssueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIssueMutation, UpdateIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIssueMutation, UpdateIssueMutationVariables>(UpdateIssueDocument, baseOptions);
      }
export type UpdateIssueMutationHookResult = ReturnType<typeof useUpdateIssueMutation>;
export type UpdateIssueMutationResult = ApolloReactCommon.MutationResult<UpdateIssueMutation>;
export type UpdateIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIssueMutation, UpdateIssueMutationVariables>;
export const UpdateProcessDocument = gql`
    mutation updateProcess($data: UpdateProcessInput) {
  updateProcess(data: $data) {
    process {
      id
      description
      state
      code
    }
  }
}
    `;
export type UpdateProcessMutationFn = ApolloReactCommon.MutationFunction<UpdateProcessMutation, UpdateProcessMutationVariables>;

/**
 * __useUpdateProcessMutation__
 *
 * To run a mutation, you first call `useUpdateProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessMutation, { data, loading, error }] = useUpdateProcessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProcessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProcessMutation, UpdateProcessMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProcessMutation, UpdateProcessMutationVariables>(UpdateProcessDocument, baseOptions);
      }
export type UpdateProcessMutationHookResult = ReturnType<typeof useUpdateProcessMutation>;
export type UpdateProcessMutationResult = ApolloReactCommon.MutationResult<UpdateProcessMutation>;
export type UpdateProcessMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProcessMutation, UpdateProcessMutationVariables>;
export const UpdateProcessUserDocument = gql`
    mutation updateProcessUser($data: UpdateProcessUserInput) {
  updateProcessUser(data: $data) {
    processUser {
      isOwner
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateProcessUserMutationFn = ApolloReactCommon.MutationFunction<UpdateProcessUserMutation, UpdateProcessUserMutationVariables>;

/**
 * __useUpdateProcessUserMutation__
 *
 * To run a mutation, you first call `useUpdateProcessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessUserMutation, { data, loading, error }] = useUpdateProcessUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProcessUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProcessUserMutation, UpdateProcessUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProcessUserMutation, UpdateProcessUserMutationVariables>(UpdateProcessUserDocument, baseOptions);
      }
export type UpdateProcessUserMutationHookResult = ReturnType<typeof useUpdateProcessUserMutation>;
export type UpdateProcessUserMutationResult = ApolloReactCommon.MutationResult<UpdateProcessUserMutation>;
export type UpdateProcessUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProcessUserMutation, UpdateProcessUserMutationVariables>;
export const CreateIssueTagDocument = gql`
    mutation createIssueTag($data: CreateIssueTagInput) {
  createIssueTag(data: $data) {
    issue {
      id
    }
    tag {
      id
    }
  }
}
    `;
export type CreateIssueTagMutationFn = ApolloReactCommon.MutationFunction<CreateIssueTagMutation, CreateIssueTagMutationVariables>;

/**
 * __useCreateIssueTagMutation__
 *
 * To run a mutation, you first call `useCreateIssueTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueTagMutation, { data, loading, error }] = useCreateIssueTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIssueTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateIssueTagMutation, CreateIssueTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateIssueTagMutation, CreateIssueTagMutationVariables>(CreateIssueTagDocument, baseOptions);
      }
export type CreateIssueTagMutationHookResult = ReturnType<typeof useCreateIssueTagMutation>;
export type CreateIssueTagMutationResult = ApolloReactCommon.MutationResult<CreateIssueTagMutation>;
export type CreateIssueTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateIssueTagMutation, CreateIssueTagMutationVariables>;
export const RemoveIssueTagDocument = gql`
    mutation removeIssueTag($data: RemoveIssueTagInput) {
  removeIssueTag(data: $data) {
    issue {
      id
    }
    tag {
      id
    }
  }
}
    `;
export type RemoveIssueTagMutationFn = ApolloReactCommon.MutationFunction<RemoveIssueTagMutation, RemoveIssueTagMutationVariables>;

/**
 * __useRemoveIssueTagMutation__
 *
 * To run a mutation, you first call `useRemoveIssueTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIssueTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIssueTagMutation, { data, loading, error }] = useRemoveIssueTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveIssueTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveIssueTagMutation, RemoveIssueTagMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveIssueTagMutation, RemoveIssueTagMutationVariables>(RemoveIssueTagDocument, baseOptions);
      }
export type RemoveIssueTagMutationHookResult = ReturnType<typeof useRemoveIssueTagMutation>;
export type RemoveIssueTagMutationResult = ApolloReactCommon.MutationResult<RemoveIssueTagMutation>;
export type RemoveIssueTagMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveIssueTagMutation, RemoveIssueTagMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserInput) {
  createUser(data: $data) {
    user {
      id
      name
      email
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String, $password: String) {
  login(email: $email, password: $password) {
    token
    user {
      id
      name
      isSuperuser
      defaultOrganization {
        id
        name
        createdAt
        updatedAt
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($data: CreateContactInput) {
  createContact(data: $data) {
    contact {
      id
      email
      name
    }
  }
}
    `;
export type CreateContactMutationFn = ApolloReactCommon.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = ApolloReactCommon.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateOrganizationWithAdminDocument = gql`
    mutation createOrganizationWithAdmin($data: CreateOrganizationWithAdminInput) {
  createOrganizationWithAdmin(data: $data) {
    organizationUser {
      organization {
        id
        name
      }
      user {
        id
        name
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateOrganizationWithAdminMutationFn = ApolloReactCommon.MutationFunction<CreateOrganizationWithAdminMutation, CreateOrganizationWithAdminMutationVariables>;

/**
 * __useCreateOrganizationWithAdminMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationWithAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationWithAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationWithAdminMutation, { data, loading, error }] = useCreateOrganizationWithAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationWithAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationWithAdminMutation, CreateOrganizationWithAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganizationWithAdminMutation, CreateOrganizationWithAdminMutationVariables>(CreateOrganizationWithAdminDocument, baseOptions);
      }
export type CreateOrganizationWithAdminMutationHookResult = ReturnType<typeof useCreateOrganizationWithAdminMutation>;
export type CreateOrganizationWithAdminMutationResult = ApolloReactCommon.MutationResult<CreateOrganizationWithAdminMutation>;
export type CreateOrganizationWithAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganizationWithAdminMutation, CreateOrganizationWithAdminMutationVariables>;
export const CreateOrganizationUserDocument = gql`
    mutation createOrganizationUser($data: CreateOrganizationUserInput) {
  createOrganizationUser(data: $data) {
    organizationUser {
      organization {
        id
        name
      }
      user {
        id
        name
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateOrganizationUserMutationFn = ApolloReactCommon.MutationFunction<CreateOrganizationUserMutation, CreateOrganizationUserMutationVariables>;

/**
 * __useCreateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationUserMutation, { data, loading, error }] = useCreateOrganizationUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationUserMutation, CreateOrganizationUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganizationUserMutation, CreateOrganizationUserMutationVariables>(CreateOrganizationUserDocument, baseOptions);
      }
export type CreateOrganizationUserMutationHookResult = ReturnType<typeof useCreateOrganizationUserMutation>;
export type CreateOrganizationUserMutationResult = ApolloReactCommon.MutationResult<CreateOrganizationUserMutation>;
export type CreateOrganizationUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganizationUserMutation, CreateOrganizationUserMutationVariables>;
export const RemoveProcessUserDocument = gql`
    mutation removeProcessUser($data: RemoveProcessUserInput) {
  removeProcessUser(data: $data) {
    errors {
      field
      message
    }
  }
}
    `;
export type RemoveProcessUserMutationFn = ApolloReactCommon.MutationFunction<RemoveProcessUserMutation, RemoveProcessUserMutationVariables>;

/**
 * __useRemoveProcessUserMutation__
 *
 * To run a mutation, you first call `useRemoveProcessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProcessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProcessUserMutation, { data, loading, error }] = useRemoveProcessUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveProcessUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProcessUserMutation, RemoveProcessUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveProcessUserMutation, RemoveProcessUserMutationVariables>(RemoveProcessUserDocument, baseOptions);
      }
export type RemoveProcessUserMutationHookResult = ReturnType<typeof useRemoveProcessUserMutation>;
export type RemoveProcessUserMutationResult = ApolloReactCommon.MutationResult<RemoveProcessUserMutation>;
export type RemoveProcessUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProcessUserMutation, RemoveProcessUserMutationVariables>;
export const ActivateDocument = gql`
    mutation activate($token: String!, $email: String!, $name: String, $password: String) {
  activate(token: $token, email: $email, name: $name, password: $password) {
    errors {
      field
      message
    }
  }
}
    `;
export type ActivateMutationFn = ApolloReactCommon.MutationFunction<ActivateMutation, ActivateMutationVariables>;

/**
 * __useActivateMutation__
 *
 * To run a mutation, you first call `useActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMutation, { data, loading, error }] = useActivateMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useActivateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateMutation, ActivateMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateMutation, ActivateMutationVariables>(ActivateDocument, baseOptions);
      }
export type ActivateMutationHookResult = ReturnType<typeof useActivateMutation>;
export type ActivateMutationResult = ApolloReactCommon.MutationResult<ActivateMutation>;
export type ActivateMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateMutation, ActivateMutationVariables>;