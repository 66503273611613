import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { OrderDirection, useTagsQuery } from '../../generated/graphql';
import { fetchOrganization } from '../../utils/Utils';

const TagSelector = ({ name, value, onChange }: { name: string, value: any, onChange: any }) => {

    const { data } = useTagsQuery({
        variables: {
            order: { field: "name", direction: OrderDirection.Asc },
            organization: "" + fetchOrganization()
        }
    });

    const options: any = data ? data?.tags?.results : [];

    const handleChange = (event: any, changedValue: any) => {
        const e = { ...event, target: { name, value: changedValue } }
        onChange(e)
    }

    return (
        <Autocomplete
            multiple
            disableClearable
            limitTags={3}
            options={options}
            value={value}
            onChange={handleChange}
            getOptionSelected={(x, y) => x.id === y.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" />
            )}
        />
    );
};

export default TagSelector;
