import Grid from '@material-ui/core/Grid';
import Viewer from 'bpmn-js/lib/Viewer';
import React, { useState } from 'react';
import { useExecuteTaskStepMutation, useProcessQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import { mediaUrl } from '../../utils/Utils';
import './Viewer.css';

const ViewerView = (props: any) => {

    useGuestRedirection()

    const [executeTaskStep] = useExecuteTaskStepMutation()

    const getDiagramFile = () => {

        let index = null;

        if (props.match.params.version) {
            const version = props.match.params.version;
            index = data?.process?.diagrams?.findIndex(diagram => diagram?.version === parseInt(version))
        } else {
            // @ts-ignore
            index = data?.process?.diagrams?.length - 1;
        }
        // @ts-ignore
        return mediaUrl(data?.process?.diagrams[index].file);
    }

    const loadDiagram = () => {

        // @ts-ignore
        if (data?.process?.diagrams?.length > 0) {

            // @ts-ignore
            const url = getDiagramFile(data?.process?.diagrams)
            const viewer = new Viewer({ container: '#processViewer', height: 800 });
            const eventBus = viewer.get('eventBus');

            fetch(url).then(res => res.text()).then(diagramXML => {
                viewer.importXML(diagramXML).then(() => {
                });
            });

            // https://github.com/bpmn-io/bpmn-js-examples/tree/master/interaction
            eventBus.on('element.click', (e: any) => {
                const task = data?.process?.tasks?.find((task: any) => task.name === e.element.businessObject.name)

                if (task?.steps) {
                    task.steps.map((step: any) => {
                        executeTaskStep({ variables: { data: { func: step.func, params: JSON.stringify(step.params) } } })
                    })
                }

                const canvas = viewer.get('canvas');
                canvas.addMarker(e.element.id, 'highlight');
                setCurrentTask(e.element.id)
            });
        }

    }

    const [, setCurrentTask] = useState(null);
    const { data, loading } = useProcessQuery({ variables: { code: props.match.params.code }, onCompleted: loadDiagram })

    return (
        <div>
            <Grid container justify="space-between">
                <h1>{data?.process?.name}</h1>
                <div>{data?.process?.tasks && data?.process?.tasks.map((task: any) => <p>{task.name}</p>)}</div>
            </Grid>
            <div id="processViewer"></div>
            <div>{loading ? 'loading diagram' : ''}</div>
        </div>
    )
}

export default ViewerView
