import { useMutation } from '@apollo/react-hooks';
import { Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import { useUserRedirection } from '../../services/Hooks';
import { CREATE_ORGANIZATION_WITH_ADMIN } from '../../services/Queries';
import { errorParser } from "../../utils/Utils";
import styles from './SignUp.module.scss';

const SignUp = () => {

    useUserRedirection();

    const [createOrganizationWithAdmin] = useMutation(CREATE_ORGANIZATION_WITH_ADMIN);
    const history = useHistory();

    const createUserSchema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        organization: Yup.string().required(),
        password: Yup.string().required(),
    });

    return (
        <Grid container justify="center" alignItems="center" className={styles.SignUp}>
            <Grid item xs={3}>
                <Grid container direction="column">
                    <h1>Register</h1>
                    <Card>
                        <CardContent>
                            <Formik
                                initialValues={{
                                    name: '',
                                    email: '',
                                    organization: '',
                                    password: '',

                                }}
                                validationSchema={createUserSchema}
                                onSubmit={(data, { setSubmitting, setErrors, resetForm }) => {

                                    const d = {
                                        organization: {
                                            name: data.organization
                                        },
                                        user: {
                                            name: data.name,
                                            email: data.email,
                                            password: data.password
                                        }
                                    }

                                    // @ts-ignore
                                    createOrganizationWithAdmin({ variables: { data: d } }).then((response, loading, error) => {
                                        setSubmitting(false);
                                        if (response.data.createOrganizationWithAdmin.errors) {
                                            const e = errorParser(response);
                                            setErrors(e);
                                        } else {
                                            history.push("/welcome");
                                        }
                                    });
                                }}
                            >
                                {({ isValid, errors, touched, dirty, submitForm, isSubmitting }) => (
                                    <Form>
                                        <Grid container direction="column">

                                            <Field
                                                component={TextField}
                                                name="name"
                                                type="text"
                                                label="Name"
                                                required
                                                data-cy="name"
                                            />

                                            <Field
                                                component={TextField}
                                                name="email"
                                                type="email"
                                                label="Email"
                                                required
                                                data-cy="email"
                                            />

                                            <Field
                                                component={TextField}
                                                name="organization"
                                                type="text"
                                                label="Organization"
                                                required
                                                data-cy="organization"
                                            />

                                            <Field
                                                component={TextField}
                                                name="password"
                                                type="password"
                                                label="Password"
                                                required
                                                data-cy="password"
                                            />

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                // @ts-ignore
                                                disabled={Boolean(isSubmitting || !isValid | !dirty)}
                                                onClick={submitForm}
                                                data-cy="submit"
                                            >
                                                Submeter
					    </Button>

                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SignUp;
