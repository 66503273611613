import AppBar from '@material-ui/core/AppBar';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useOrganizationUsersQuery, useTagsQuery } from '../../generated/graphql';
import { fetchOrganization } from '../../utils/Utils';
import Loading from '../Loading/Loading';
import Search from '../Search/Search';
import ProcessSelector from '../ProcessSelector/ProcessSelector';


const StateFilter = ({ value, onChange }: { value: any, onChange: any }) => {
    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="state">
                Estado
            </InputLabel>
            <Select id="state" name="state" onChange={onChange} value={value || ''}>
                <MenuItem value={''}>TODOS</MenuItem>
                <MenuItem value="CREATED">Criada</MenuItem>
                <MenuItem value="PENDING">Pendente</MenuItem>
                <MenuItem value="PROGRESS">Progresso</MenuItem>
                <MenuItem value="RESOLVED">Resolvida</MenuItem>
                <MenuItem value="CANCELED">Cancelada</MenuItem>
            </Select>
        </FormControl>
    );
};

const PriorityFilter = ({ value, onChange }: { value: any, onChange: any }) => {
    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="priority">
                Prioridade
            </InputLabel>
            <Select id="priority" name="priority" onChange={onChange} value={value || ''}>
                <MenuItem value={''}>TODOS</MenuItem>
                <MenuItem value="1">1 (min)</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5 (max)</MenuItem>
            </Select>
        </FormControl>
    );
};


const UserFilter = ({ value, onChange }: { value: any, onChange: any }) => {

    const { data, loading } = useOrganizationUsersQuery({ variables: { organization: fetchOrganization() } })

    if (loading) {
        return <Loading />;
    }

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="user">
                Autor
            </InputLabel>
            <Select id="user" name="user" onChange={onChange} value={value || ''}>
                <MenuItem value={''}>TODOS</MenuItem>
                {data?.organizationUsers?.results?.map((organizationUser: any) => <MenuItem key={organizationUser.user.id} value={organizationUser.user.id}>{organizationUser.user.name}</MenuItem>)}
            </Select>
        </FormControl>
    );
};


const TagFilter = ({ value, onChange }: { value: any, onChange: any }) => {

    const { data, loading } = useTagsQuery({ variables: { organization: fetchOrganization() } })

    if (loading) {
        return <Loading />;
    }

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="tag">
                Etiqueta
            </InputLabel>
            <Select id="tag" name="tag" onChange={onChange} value={value || ''}>
                <MenuItem value={''}>TODOS</MenuItem>
                {data?.tags?.results?.map((tag: any) => <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>)}
            </Select>
        </FormControl>
    );
};


/* const ViewSwitcher = (props: any) => {
 *
 *     const handleToggle = () => {
 *         const view = props.type === 'LIST' ? 'GRID' : 'LIST';
 *         props.onChange({ view });
 *     };
 *
 *     return (
 *         <div style={{ marginLeft: '400px' }}>
 *             <IconButton onClick={handleToggle}>
 *                 <Tooltip title={props.type === 'LIST' ? 'Lista' : 'Grelha'}>
 *                     {props.type === 'LIST' ? <ViewListIcon /> : <ViewModuleIcon />}
 *                 </Tooltip>
 *             </IconButton>
 *         </div>
 *     );
 * }; */

const Filterbar = ({ filters, onChange }: { filters: any, onChange: any }) => {
    return (
        <AppBar position="static" color="transparent">
            <Toolbar>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={2}>
                    <Grid item xs={2}>
                        <Search value={filters.search} onChange={onChange} placeholder="pesquisar descrição" />
                    </Grid>
                    <Grid item xs={2}>
                        <ProcessSelector value={filters.process} onChange={onChange} />
                    </Grid>
                    <Grid item xs={1}>
                        <UserFilter value={filters.user} onChange={onChange} />
                    </Grid>
                    <Grid item xs={1}>
                        <StateFilter value={filters.state} onChange={onChange} />
                    </Grid>
                    <Grid item xs={1}>
                        <PriorityFilter value={filters.priority} onChange={onChange} />
                    </Grid>
                    <Grid item xs={1}>
                        <TagFilter value={filters.tag} onChange={onChange} />
                    </Grid>


                    {/* <Grid item xs={2}>
                        <ViewSwitcher alignSelf="flex-end" type={filters.view} onChange={onChange} />
			</Grid> */}
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Filterbar;
