import { useMutation } from '@apollo/react-hooks';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import { Organization, useOrganizationUsersQuery } from '../../generated/graphql';
import { LOGOUT_USER } from '../../services/Local';
import { fetchUser, isCurrentOrganization } from '../../utils/Utils';
import CustomModal from '../CustomModal/CustomModal';
import KeyboardShortcuts from '../KeyboardShortcuts/KeyboardShortcuts';
import Loading from '../Loading/Loading';
import styles from './Header.module.scss';

const FullHeader = () => {
    // const location = useLocation();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [logoutUser] = useMutation(LOGOUT_USER);
    const { data, loading } = useOrganizationUsersQuery({ variables: { user: fetchUser() } })

    /* if (location.pathname === '/') {
     *     return null;
     * } */

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    /* const handleMenuAction = () => {
     *     setIsMenuOpen(false);
     *     history.push('/profile');
     * }; */

    const handleLogout = () => {
        setIsMenuOpen(false);
        logoutUser().then(() => history.push('/login'));
    };

    const switchOrganization = (organization?: Organization) => {
        setIsMenuOpen(false)
        // @ts-ignore
        localStorage.setItem('auth.organization', organization.id)
        history.push('/issues/')
        window.location.reload();
    }

    if (loading) {
        return <Loading />
    }

    return (
        <Grid container justify="center" className={styles.Header}>
            <Grid container justify="space-between" alignItems="center">
                <Link to='/issues'>
                    <img src={Logo} alt="register logo" />
                </Link>
                <div>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                    >
                        {data?.organizationUsers?.results?.map(organizationUser => (
                            <MenuItem
                                className={classNames({ [styles.SelectedOrganization]: isCurrentOrganization(organizationUser?.organization.id) })}
                                onClick={() => switchOrganization(organizationUser?.organization)}
                                key={organizationUser?.id}
                            >
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <Typography variant="inherit" >
                                    {organizationUser?.organization.name}
                                </Typography>
                            </MenuItem>

                        )
                        )}

                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Logout
			    </Typography>
                        </MenuItem>
                    </Menu>

                    <NavLink to="/issues" activeClassName={styles.Active}>Ocorrências</NavLink>
                    <NavLink to="/tags" activeClassName={styles.Active}>Etiquetas</NavLink>
                    <NavLink to="/processes" activeClassName={styles.Active}>Processos</NavLink>
                    <NavLink to="/users" activeClassName={styles.Active}>Utilizadores</NavLink>


                    {/* <IconButton>
			<Badge badgeContent=" " color="secondary" variant="dot" overlap="circle">
			<Tooltip title="Notifications">
			<NotificationsIcon />
			</Tooltip>
			</Badge>
			</IconButton> */}
                    <IconButton onClick={handleProfileMenuOpen}>
                        <AccountCircleIcon />
                    </IconButton>
                </div>
                <CustomModal
                    isActive={isModalOpen}
                    title="Keyboard Shortcuts"
                    handleClose={() => setModalOpen(false)}>
                    <KeyboardShortcuts />
                </CustomModal>

            </Grid>
        </Grid >
    );
};

const MobileHeader = () => {
    return (
        <div className={styles.MobileHeader}>
            <Grid container justify="space-between" alignItems="center" className={styles.Bar}>
                <IconButton>
                    <Tooltip title="Notifications">
                        <MenuIcon />
                    </Tooltip>
                </IconButton>


                <TextField variant="outlined" placeholder="search issues" size="small" className={styles.Search} />

                <IconButton>
                    <Badge badgeContent=" " color="secondary" variant="dot" overlap="circle">
                        <Tooltip title="Notifications">
                            <NotificationsIcon />
                        </Tooltip>
                    </Badge>
                </IconButton>
            </Grid>
        </div >
    )
}

const Header = () => {
    const matches = useMediaQuery('(min-width:600px)');
    return matches ? <FullHeader /> : <MobileHeader />;
};


export default Header;
