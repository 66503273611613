import Button from '@material-ui/core/Button';
import { Field, Form, Formik } from 'formik';
import { SimpleFileUpload, TextField } from 'formik-material-ui';
import React from 'react';
import { number, object, string } from "yup";
import { useCreateDiagramMutation } from '../../generated/graphql';

const CreateDiagram = (props: any) => {

    const [createDiagram] = useCreateDiagramMutation()

    const schema = object().shape({
        version: number().min(1),
        process: string(),
        file: string(),
        observations: string()
    })

    const initialValues = {
        version: '',
        process: props.process,
        file: '',
        observations: ''
    }

    const handleSubmit = (data: any) => {
        return createDiagram({ variables: { data } });
    }

    return (
        <div>
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                {() => (
                    <Form>
                        <Field
                            component={TextField}
                            name="version"
                            type="number"
                            label="Version"
                            fullWidth
                        />

                        <Field
                            component={SimpleFileUpload}
                            name="file"
                            label="Diagram File"
                            fullWidth
                        />

                        <Field
                            component={TextField}
                            name="observations"
                            type="text"
                            label="Observations"
                            fullWidth
                        />

                        <Button type="submit" color="primary">
                            Criar Diagrama
			</Button>

                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateDiagram;
