import Grid from '@material-ui/core/Grid';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import React from 'react';
import styles from './EmptyState.module.scss';

const EmptyState = () => {
    const title = 'Sem Resultados.'
    const message = 'Tente ajustar a pesquisa ou os filtros.'
    return (
        <Grid container justify="center"
            alignItems="center" direction="column"
            className={styles.EmptyStateContainer}>
            <FolderOpenIcon className={styles.Icon} />
            <div className={styles.Title}>{title}</div>
            <div className={styles.Message}>{message}</div>
        </Grid>
    )
}

export default EmptyState;
