import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const KeyboardShortcuts = () => (
    <div>
        <List>
            <ListItem>
                a - Add (not available yet)
	    </ListItem>
            <ListItem>
                g - Grid View (not available yet)
	    </ListItem>
            <ListItem>
                i - Go to issues view (not available yet)
	    </ListItem>
            <ListItem>
                l - List View (not available yet)
	    </ListItem>
            <ListItem>
                p - Go to processes view (not available yet)
	    </ListItem>
            <ListItem>
                s - Search
	    </ListItem>
            <ListItem>
                u - Go to issues view (not available yet)
	    </ListItem>
        </List>
    </div>

);

export default KeyboardShortcuts;
