import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import { useRemoveProcessUserMutation, useUpdateProcessUserMutation, ProcessUser } from '../../generated/graphql';
import Button from '@material-ui/core/Button';

const ParticipantsEditor = (participants: any) => {

    const [updateProcessUser] = useUpdateProcessUserMutation()
    const [removeProcessUser] = useRemoveProcessUserMutation()

    const handleChange = (participant: ProcessUser) => {
        updateProcessUser({ variables: { data: { processUser: participant.id, isOwner: !participant.isOwner } } })
    }

    const handleClick = (participant: ProcessUser) => {
        removeProcessUser({ variables: { data: { processUser: participant.id } } })
    }

    return (
        <p>
            {!participants && 'Não Existem Participantes.'}
            {participants?.participants.map((participant: any) => (
                <p>
                    #{participant.id} - {participant.user.name} |  {participant.isOwner ? 'Dono' : 'Participante'}

                    <ToggleButton
                        value="check"
                        selected={participant.isOwner}
                        onChange={() => handleChange(participant)}
                    >
                        <CheckIcon />
                    </ToggleButton>

                    <Button onClick={() => handleClick(participant)}>APAGAR</Button>
                </p>
            ))
            }
        </p>
    )
}


export default ParticipantsEditor;
