import { useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FabContext } from '../services/FabProvider';

export const useGuestRedirection = () => {
    const history = useHistory();
    const location = useLocation();
    const auth_token = localStorage.getItem('auth.token')
    if (!auth_token) {
        history.push('/login', { redirectTo: location.pathname })
    }
};

export const useUserRedirection = () => {
    const history = useHistory();
    const auth_token = localStorage.getItem('auth.token');

    if (auth_token) {
        let url = '/';
        try {
            // @ts-ignore
            url = history.location.state.redirectTo;
        }
        catch (error) {
            console.error(error);
        }
        history.push(url);
    }
};

/* eslint-disable react-hooks/exhaustive-deps */
export const useGlobalFab = (callback: any) => {
    const { setAction } = useContext(FabContext);
    useEffect(() => {
        setAction(() => callback);
        return () => setAction(() => null);
    }, [setAction]);
}

// https://stackoverflow.com/a/54159564
export const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        // @ts-ignore
        htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
};
