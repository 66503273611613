import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { OrderDirection, useTagsQuery } from '../../generated/graphql';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import { fetchOrganization } from '../../utils/Utils';
import CreateTag from '../CreateTag/CreateTag';
import CustomModal from '../CustomModal/CustomModal';
import EmptyState from '../EmptyState/EmptyState';
import Loading from '../Loading/Loading';
import styles from './TagsListView.module.scss';
import Search from '../Search/Search';

const TagsFilterBar = (props: any) => {
    return (
        <AppBar position="static" color="transparent">
            <Toolbar>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={2}>
                    <Grid item xs={2}>
                        <Search value={props.search} onChange={props.onChange} placeholder="pesquisar nome" />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const TagsList = ({ tags }: { tags: any }) => (

    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tags.map((tag: any) => (
                    <TableRow key={tag.id}>
                        <TableCell component="th" scope="tag">
                            {tag.id}
                        </TableCell>
                        <TableCell component="th" scope="tag">
                            {tag.name}
                        </TableCell>
                        <TableCell align="left" className={styles.Description}>
                            <Tooltip title={tag.description}>
                                <span>
                                    {tag.description}
                                </span>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const TagPreview = (props: any) => {
    return (
        <Card className={styles.TagPreview} onClick={() => props.view(props.tag)}>
            <CardHeader title={'#' + props.tag.id} subheader={props.tag.name} />
            <CardContent>
                {props.tag.description}
            </CardContent>
        </Card>
    );
};

const TagsGrid = ({ tags, onOrder, view }: { tags: any, onOrder: any, view: any }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleSort = (field: string, direction: string) => {
        setIsMenuOpen(false);
        onOrder(field, direction);
    };

    if (tags.length === 0) {
        return <EmptyState />
    }

    return (
        <Grid container justify="center" className={styles.TagsGrid}>
            <Grid container item xs={12}>
                <IconButton onClick={handleProfileMenuOpen}>
                    <Tooltip title="ordenar">
                        <SortIcon />
                    </Tooltip>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleSort('id', 'ASC')}>ID (ascendente)</MenuItem>
                    <MenuItem onClick={() => handleSort('id', 'DESC')}>ID (descendente)</MenuItem>
                </Menu>

            </Grid>
            <Grid container item xs={10} spacing={4}>
                {tags.map((tag: any) => (
                    <Grid item xs={3} key={tag.id}>
                        <TagPreview tag={tag} view={view} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};


const TagsListView = () => {

    useGuestRedirection();

    const [isModalOpen, setModalOpen] = useState(false);
    const [filters, setFilters] = useState({ search: "", view: 'GRID', page: 1, order: { field: "name", direction: OrderDirection.Asc }, organization: "" + fetchOrganization() });
    const { data, loading, refetch } = useTagsQuery({ variables: filters })

    useGlobalFab(() => setModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    const onComplete = () => {
        setModalOpen(false);
        refetch();
    };

    const handleChange = (event: any) => {
        const name = event.target ? event.target.name : 'view';
        const value = event.target ? event.target.value : event.view;
        if (value === '') {
            const newFilters = { ...filters };
            // @ts-ignore
            delete newFilters[name];
            // @ts-ignore
            setFilters({ ...newFilters });
        } else {
            setFilters({ ...filters, [name]: "" + value });
        }

    };

    const handleOrder = (field: string, direction: string) => {
        // @ts-ignore
        setFilters({ ...filters, order: { field, direction } });
    };

    const handlePagination = (_: any, page: number) => {
        setFilters({ ...filters, page });
    };

    const tags = data?.tags?.results;

    return (
        <>
            <TagsFilterBar search={filters.search} onChange={handleChange} />
            {filters.view === 'LIST' ? <TagsList tags={tags} /> : <TagsGrid tags={tags} onOrder={handleOrder} view={() => console.log('hello')} />}
            <Grid container justify="center" className={styles.Pagination}>
                {tags && tags?.length > 0 && <Pagination count={data?.tags?.count ? Math.ceil(data.tags.count / 25) : 0} page={filters.page} onChange={handlePagination} />}
            </Grid>
            <CustomModal
                isActive={isModalOpen}
                title="Create Tag"
                handleClose={() => setModalOpen(false)}>
                <CreateTag onComplete={onComplete} />
            </CustomModal>
        </>
    );
};

export default TagsListView;
