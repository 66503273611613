import gql from 'graphql-tag';

// fragments


// compound queries

export const PROCESS_PLUS_FEATURES = gql`
query processPlusFeatures($organization: ID, $code: String, $id: ID) {
    features(organization: $organization) {
        count
        results
    }
    process(code: $code, id: $id) {
        id
        name
        code
        description
        state
        diagrams {
            id
            file
            version
        }
        processUsers {
            id
            isOwner
            user {
                id
                name
            }
        }
    }

}
`;




// queries

export const FEATURES = gql`
query features($organization: ID) {
    features(organization: $organization) {
        count
        results
    }
}
`;



export const ME = gql`
query me {
    me {
        user {
            id
            name
            email
            isEmailVerified
        }
        errors {
            field
            message
        }
    }
}
`;

export const ISSUES = gql`
query issues($state: IssueState, $process: ID, $user: ID, $order: OrderInput, $page: Int, $search: String, $priority: Int, $organization: ID) {
    issues(state: $state, process: $process, user: $user, order: $order, page: $page, search: $search, priority: $priority, organization: $organization) {
        count
        results {
            id
            name
            description
            nature
            state
            observations
            issueDate
            priority
            sequence
            user {
                id
                name
            }
            process {
                id
                name
            }
            tags {
                id
                name
            }
            createdAt
        }
    }
}
`;

export const GET_ISSUES = gql`
query getIssues($state: IssueState, $process: ID, $user: ID, $order: OrderInput, $page: Int, $search: String, $priority: Int, $organization: ID, $tag: ID) {
    getIssues(state: $state, process: $process, user: $user, order: $order, page: $page, search: $search, priority: $priority, organization: $organization, tag: $tag) {
        count
        results {
            id
            name
            description
            nature
            state
            observations
            issueDate
            priority
            sequence
            user {
                id
                name
            }
            process {
                id
                name
            }
            tags {
                id
                name
            }
            createdAt
        }
    }
}
`;


export const PROCESS = gql`
query process($code: String, $id: ID) {
    process(code: $code, id: $id) {
        id
        name
        code
        description
        state
        diagrams {
            id
            file
            version
        }
        processUsers {
            id
            isOwner
            user {
                id
                name
            }
        }
        tasks
    }
}
`;


export const PROCESSES = gql`
query processes($order: OrderInput, $page: Int, $pageSize: Int, $search: String, $organization: ID) {
    processes(order: $order, page: $page, pageSize: $pageSize, search: $search, organization: $organization) {
        count
        results {
            id
            name
            code
            description
            state
            diagrams {
                id
                file
                version
            }

        }
    }
}
`;

export const USERS = gql`
query users {
    users {
        count
        results {
            id
            name
        }
    }
}
`;

export const STATUS = gql`
query status {
    status
}
`;

export const TAGS = gql`
query tags($organization: ID, $order: OrderInput, $search: String, $page: Int, $pageSize: Int) {
    tags(organization: $organization, order: $order, search: $search, page: $page, pageSize: $pageSize) {
        count
        results {
            id
            name
            createdAt
            updatedAt
        }
    }
}
`;

export const ORGANIZATION_USERS = gql`
query organizationUsers($organization: ID, $user: ID, $search: String, $page: Int, $pageSize: Int, $order: OrderInput) {
    organizationUsers(organization: $organization, user: $user, search: $search, page: $page, pageSize: $pageSize, order: $order) {
        count
        results {
            id
            user {
                id
                name
                email
                state
            }
            organization {
                id
                name
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
}
`;


// mutations

export const CREATE_DIAGRAM = gql`
mutation createDiagram($data: CreateDiagramInput) {
    createDiagram(data: $data) {
        diagram {
            id
            version
            file
        }
        errors {
            field
            message
        }
    }
}
`;


export const CREATE_PROCESS = gql`
mutation createProcess($data: CreateProcessInput) {
    createProcess(data: $data) {
        process {
            id
            name
        }
        errors {
            field
            message
        }
    }
}
`;

export const CREATE_PROCESS_WITH_OWNER = gql`
mutation createProcessWithOwner($data: CreateProcessWithOwnerInput) {
    createProcessWithOwner(data: $data) {
        process {
            id
            name
        }
        errors {
            field
            message
        }
    }
}
`;


export const CREATE_ISSUE = gql`
mutation createIssue($data: CreateIssueInput) {
  createIssue(data: $data) {
    issue {
      id
      description
    }
  }
}
`;

export const CREATE_TAG = gql`
mutation createTag($data: CreateTagInput) {
    createTag(data: $data) {
        tag {
            id
            name
        }
        errors {
            field
            message
        }
    }
}
`;

export const EXECUTE_TASK_STEP = gql`
    mutation executeTaskStep($data: ExecuteTaskStepInput) {
        executeTaskStep(data: $data)
}
`;


export const UPDATE_ISSUE = gql`
mutation updateIssue($data: UpdateIssueInput) {
    updateIssue(data: $data) {
        issue {
            id
            description
            state
            nature
            observations
        }
    }
}
`;

export const UPDATE_PROCESS = gql`
mutation updateProcess($data: UpdateProcessInput) {
    updateProcess(data: $data) {
        process {
            id
            description
            state
            code
        }
    }
}
`;

export const UPDATE_PROCESS_USER = gql`
mutation updateProcessUser($data: UpdateProcessUserInput) {
    updateProcessUser(data: $data) {
        processUser {
            isOwner
        }
        errors {
            field
            message
        }
    }
}
`;

export const CREATE_ISSUE_TAG = gql`
mutation createIssueTag($data: CreateIssueTagInput) {
    createIssueTag(data: $data) {
        issue {
            id
        }
        tag {
            id
        }
    }
}
`;

export const REMOE_ISSUE_TAG = gql`
mutation removeIssueTag($data: RemoveIssueTagInput) {
    removeIssueTag(data: $data) {
        issue {
            id
        }
        tag {
            id
        }
    }
}
`;



export const CREATE_USER = gql`
mutation createUser($data: CreateUserInput) {
    createUser(data: $data) {
        user {
            id
            name
            email
        }
        errors {
            field
            message
        }
    }
}
`;

export const LOGIN = gql`
mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
        token
        user {
            id
            name
            isSuperuser
            defaultOrganization {
                id
                name
                createdAt
                updatedAt
            }
        }
        errors {
            field
            message
        }
    }
}
`;

export const CREATE_CONTACT = gql`
mutation createContact($data: CreateContactInput) {
  createContact(data: $data) {
    contact {
      id
      email
      name
    }
  }
}
`;


export const CREATE_ORGANIZATION_WITH_ADMIN = gql`
mutation createOrganizationWithAdmin($data: CreateOrganizationWithAdminInput) {
  createOrganizationWithAdmin(data: $data) {
    organizationUser {
        organization {
            id
            name
        }
        user {
            id
            name
        }
    }
    errors {
        field
        message
    }
  }
}
`;

export const CREATE_ORGANIZATION_USER = gql`
mutation createOrganizationUser($data: CreateOrganizationUserInput) {
  createOrganizationUser(data: $data) {
    organizationUser {
        organization {
            id
            name
        }
        user {
            id
            name
        }
    }
    errors {
        field
        message
    }
  }
}
`;

export const REMOVE_PROCESS_USER = gql`
mutation removeProcessUser($data: RemoveProcessUserInput) {
    removeProcessUser(data: $data) {
        errors {
            field
            message
        }
    }
}
`;

export const ACTIVATE = gql`
mutation activate($token: String!, $email: String!, $name: String, $password: String) {
    activate(token: $token, email: $email, name: $name, password: $password) {
        errors {
            field
            message
        }
    }
}
`;
