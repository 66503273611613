import { capitalize } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CommentIcon from '@material-ui/icons/Comment';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import classNames from 'classnames';
import React from 'react';
import { shortHumanizedDate } from '../../constants';
import { Issue } from '../../generated/graphql';
import { issueNatures, joinTags, truncateString, issueStates } from '../../utils/Utils';
import FormatDate from '../FormatDate/FormatDate';
import styles from './IssuePreview.module.scss';


const IssuePreview = ({ issue, view }: { issue: Issue, view: any }) => {

    const state = issue?.state ? capitalize(issue?.state.toLowerCase()) : 'Created'
    // @ts-ignore
    const stateName: string = issue?.state ? issueStates(issue.state) : ''

    return (
        <Card className={styles.IssuePreview} onClick={() => view(issue)}>
            <Grid container direction="column" justify="space-between" className={styles.ContentWrapper}>
                <Grid container justify="space-between" alignItems="center">
                    <span className={styles.IssueId}>
                        ref #{issue.sequence}
                    </span>
                    <div>
                        <Grid container alignItems="center">
                            <span className={styles.Nature}>
                                {issueNatures(issue.nature)}
                            </span>
                            <Tooltip title={stateName}>
                                <span>
                                    <BulletIcon className={classNames({ [styles[`${state}State`]]: true })} />
                                </span>
                            </Tooltip>
                        </Grid>
                    </div>
                </Grid>

                <h3>
                    <Tooltip title={issue.name || 'Sem Nome'}>
                        <span>
                            {issue?.name ? truncateString(issue.name, 25, true) : 'Sem Nome'}
                        </span>
                    </Tooltip>
                </h3>
                <h4>{issue.process?.name || <div>&nbsp;</div>}</h4>

                <p className={styles.Description}>
                    {truncateString(issue.description, 75, true)}
                </p>

                <Grid container justify="space-between" className={styles.Footer}>
                    <Grid container item xs={6} alignItems="center">
                        <Tooltip title={'criado por: ' + issue.user?.name || ''}>
                            <AccountCircleIcon />
                        </Tooltip>
                        <Tooltip title={'criado em'}>
                            <FormatDate date={issue.createdAt} format={shortHumanizedDate} />
                        </Tooltip>
                    </Grid>


                    <Grid container item xs={6} justify="flex-end" alignItems="center">
                        <Tooltip title={issue.observations ? issue.observations : 'sem observações'}>
                            <CommentIcon />
                        </Tooltip>
                        <span style={{ paddingRight: '8px' }}>{issue.observations ? 1 : 0}</span>
                        <Tooltip title={joinTags(issue)}>
                            <LocalOfferIcon />
                        </Tooltip>
                        <span>{issue.tags?.length}</span>
                    </Grid>
                </Grid>
            </Grid>
        </Card >
    );
};


export default IssuePreview;
