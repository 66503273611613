import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import * as Yup from "yup";
import { UPDATE_PROCESS } from '../../services/Queries';
import { errorParser } from '../../utils/Utils';
import CreateDiagram from '../CreateDiagram/CreateDiagram';
import MultiStateToggle from '../MultiStateToggle/MultiStateToggle';
import styles from './EditUser.module.scss';

const ShowMore = ({ value, onClick }: { value: Boolean, onClick: any }) => {
    return (
        <Grid container alignItems="center" justify="flex-end" onClick={() => onClick(!value)}>
            <span style={{ cursor: "pointer" }}>Mostrar Mais</span>
            <IconButton>
                {value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Grid>
    )
}

const EditUser = ({ process, onComplete }: { process: any, onComplete: any }) => {

    const [updateProcess] = useMutation(UPDATE_PROCESS);
    const [showMore, setShowMore] = useState(false)

    const stateOptions = [
        { id: "INITIAL", label: "Inicial" },
        { id: "PREDICTABLE", label: "Previsível" },
        { id: "OPTIMIZED", label: "Otimizado" },
    ]

    const submit = (values: any) => {
        const data = { id: values.id, name: values.name, code: values.code, description: values.description, state: values.state }
        return updateProcess({ variables: { data } });
    };

    const createProcesseschema = Yup.object().shape({});

    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.Wrapper}>

            <p className={styles.Name}>
                Editar
            </p>

            <Formik
                initialValues={{
                    ...process,
                }}
                validationSchema={createProcesseschema}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                    submit(values).then((response: any) => {
                        setSubmitting(false);
                        if (response.data.updateProcess.errors) {
                            const e = errorParser(response);
                            setErrors(e);
                        } else {
                            resetForm();
                            onComplete();
                        }
                    });
                }}
            >
                {({ isValid, submitForm, isSubmitting }) => (
                    <Form style={{ width: '100%' }}>

                        <p className={styles.SectionHeader}>
                            Nome
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="name"
                            type="text"
                            fullWidth
                            data-cy="name"
                        />

                        <p className={styles.SectionHeader}>
                            Code
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="code"
                            type="text"
                            fullWidth
                            data-cy="code"
                        />


                        <p className={styles.SectionHeader}>
                            Descrição
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="description"
                            type="text"
                            fullWidth
                            multiline
                            rows="3"
                            data-cy="description"
                        />

                        <p className={styles.SectionHeader}>
                            Estado
			</p>

                        <Field
                            name="state"
                            as={MultiStateToggle}
                            options={stateOptions}
                        />

                        <ShowMore onClick={setShowMore} value={showMore} />

                        {showMore && <div>

                            <p className={styles.SectionHeader}>
                                Diagramas
			    </p>

                            <CreateDiagram process={process.id} />

                        </div>}

                        <div style={{ float: "right" }}>

                            <Button
                                variant="contained"
                                color="primary"
                                disabled={Boolean(isSubmitting || !isValid)}
                                onClick={submitForm}
                                data-cy="submit"
                            >
                                Atualizar
			    </Button>
                        </div>

                    </Form>
                )}
            </Formik>
        </Grid >
    );
};

export default EditUser;
