import 'moment/locale/pt';
import React from 'react';
import Moment from 'react-moment';

const FormatDate = ({ date, timestamp, format }: { date?: any, timestamp?: string, format?: string }) => {
    if (!date && !timestamp) {
        return null;
    }

    if (timestamp) {
        const t = parseInt(timestamp, 10) / 1000;
        return (
            <Moment locale="pt" unix format={format ? format : "DD-MM-YYYY"}>
                {t}
            </Moment>);
    }

    return (
        <Moment locale="pt" format={format ? format : "DD-MM-YYYY"}>
            {date}
        </Moment>);
};

export default FormatDate;
