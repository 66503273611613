import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useOrganizationUsersQuery } from '../../generated/graphql';

const OrganizationUsersSelector = ({ name, value, onChange }: { name: string, value: any, onChange: any }) => {

    const { data } = useOrganizationUsersQuery({ variables: { organization: "22" } })

    const options: any = data ? data?.organizationUsers?.results : [];

    const handleChange = (event: any, changedValue: any) => {
        const e = { ...event, target: { name, value: changedValue } }
        onChange(e)
    }

    return (
        <Autocomplete
            disableClearable
            limitTags={3}
            options={options}
            value={value}
            onChange={handleChange}
            getOptionSelected={(x, y) => x.id === y.id}
            getOptionLabel={(option) => option.user.name}
            data-cy="organizationUsers"
            renderInput={(params) => (
                <TextField {...params} variant="outlined" />
            )}
        />
    );
};

export default OrganizationUsersSelector;
