import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { User } from '../generated/graphql';
import { fetchOrganization } from '../utils/Utils';
import { Config } from "./Config";
import { FEATURES } from './Queries';

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('auth.token');
    const prefix = Config.JWT_AUTH_HEADER_PREFIX;
    return {
        headers: {
            ...headers,
            'authorization': token ? `${prefix} ${token}` : "",
        }
    };
});

const uploadLink = createUploadLink({
    uri: Config.GRAPHQL_API
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(uploadLink),
    resolvers: {
        Mutation: {
            loginUser: (_root, variables, { cache }) => {
                const token = variables.token
                const user: User = variables.user
                cache.writeData({ data: { token } });
                localStorage.setItem('auth.token', token);
                localStorage.setItem('auth.user', user.id || '');
                localStorage.setItem('auth.organization', user?.defaultOrganization?.id || '');
                localStorage.setItem('auth.isSuperuser', user?.isSuperuser ? "1" : "0");
                setInitialData()
                return true
            },
            logoutUser: (_root, _, { cache }) => {
                localStorage.removeItem('auth.token');
                localStorage.removeItem('auth.user');
                localStorage.removeItem('auth.organization');
                localStorage.removeItem('auth.isSuperuser')
                cache.reset()
                return null;
            }
        },
    }

});

const setInitialData = () => {
    const organization = fetchOrganization()
    if (organization) {
        client.query({ query: FEATURES, variables: { organization } })
    }
}

setInitialData()


export default client;
