import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { FC, useState } from 'react';
import * as Yup from "yup";
import { Issue, Tag } from '../../generated/graphql';
import { CREATE_ISSUE, UPDATE_ISSUE } from '../../services/Queries';
import { errorParser } from '../../utils/Utils';
import MultiStateToggle from '../MultiStateToggle/MultiStateToggle';
import ProcessSelector from '../ProcessSelector/ProcessSelector';
import TagSelector from '../TagSelector/TagSelector';
import styles from './CreateOrEditIssue.module.scss';

interface CreateOrEditIssueProps {
    issue?: Issue;
    onComplete?: () => void;
}

interface ShowMoreProps {
    value: boolean;
    onClick: (value: boolean) => void;
}

const ShowMore: FC<ShowMoreProps> = ({ value, onClick }) => {
    return (
        <Grid container alignItems="center" justify="flex-end" onClick={() => onClick(!value)}>
            <span style={{ cursor: "pointer" }}>Mostrar Mais</span>
            <IconButton>
                {value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Grid>
    )
}

const CreateOrEditIssue: FC<CreateOrEditIssueProps> = ({ issue, onComplete }) => {

    const [updateIssue] = useMutation(UPDATE_ISSUE)
    const [createIssue] = useMutation(CREATE_ISSUE)
    const [showMore, setShowMore] = useState(false)
    const action = issue ? 'Editar' : 'Criar'
    const submitName = issue ? 'Atualizar' : 'Criar'

    const priorityOptions = [
        { id: 1, label: "1 (min)" },
        { id: 2, label: "2" },
        { id: 3, label: "3" },
        { id: 4, label: "4" },
        { id: 5, label: "5 (max)" },
    ]

    const stateOptions = [
        { id: "CREATED", label: "Criada" },
        { id: "PENDING", label: "Pendente" },
        { id: "PROGRESS", label: "Progresso" },
        { id: "CANCELED", label: "Cancelada" },
        { id: "RESOLVED", label: "Resolvida" }
    ]

    const natureOptions = [
        { id: "OCCURRENCE", label: "Ocorrência" },
        { id: "OPPORTUNITY", label: "Oportunidade" },
    ]

    const submit = async (values: any) => {

        let data: any = {
            name: values.name,
            description: values.description,
            state: values.state,
            nature: values.nature,
            observations: values.observations,
            process: parseInt(values.process, 10),
            priority: parseInt(values.priority, 10)
        };

        if (issue) {
            data.issue = parseInt(values.id, 10);
        }

        if (values.tags) {
            data['tags'] = values.tags.map((tag: Tag) => tag.id);
        }

        const payload = { variables: { data } }
        return issue ? await updateIssue(payload) : await createIssue(payload);
    };

    const schema = Yup.object().shape({
        name: Yup.string(),
        process: Yup.number().required(),
        nature: Yup.string().required(),
        state: Yup.string().required(),
        description: Yup.string().required('Campo Obrigatório'),
        observations: Yup.string().nullable(),
        priority: Yup.number().min(1).max(5).required()
    });

    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.Wrapper}>

            <p className={styles.Name}>
                {action}
            </p>

            <Formik
                initialValues={{
                    ...issue,
                    process: issue?.process?.id
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                    submit(values).then((response: any) => {
                        setSubmitting(false);
                        if (response?.data?.updateIssue?.errors) {
                            const e = errorParser(response);
                            setErrors(e);
                        } else {
                            resetForm();
                            if (onComplete) {
                                onComplete()
                            }
                        }
                    });
                }}
            >
                {({ isValid, submitForm, isSubmitting, values, setFieldValue }) => (
                    <Form style={{ width: '100%' }}>

                        <p className={styles.SectionHeader}>
                            Nome
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="name"
                            type="text"
                            fullWidth
                            data-cy="name"
                            autoFocus
                        />

                        <p className={styles.SectionHeader}>
                            Processo
			</p>

                        <ProcessSelector value={values.process} variant="outlined"
                            onChange={(event: any) => setFieldValue(event.target.name, event.target.value)} />


                        <p className={styles.SectionHeader}>
                            Descrição
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="description"
                            type="text"
                            fullWidth
                            multiline
                            rows="3"
                            data-cy="description"
                        />

                        <p className={styles.SectionHeader}>
                            Prioridade
			</p>


                        <Field
                            name="priority"
                            as={MultiStateToggle}
                            options={priorityOptions}
                        />

                        <p className={styles.SectionHeader}>
                            Tipo
			</p>

                        <Field name="nature" as={MultiStateToggle} options={natureOptions} />

                        <p className={styles.SectionHeader}>
                            Estado
			</p>

                        <Field
                            name="state"
                            as={MultiStateToggle}
                            options={stateOptions}
                        />

                        <ShowMore onClick={setShowMore} value={showMore} />

                        {showMore && <div>

                            <p className={styles.SectionHeader}>
                                Etiquetas
			    </p>

                            <Field name="tags" as={TagSelector} />

                            <p className={styles.SectionHeader}>
                                Observações
			    </p>

                            <Field
                                component={TextField}
                                variant="outlined"
                                name="observations"
                                type="text"
                                fullWidth
                                multiline
                                rows="3"
                                data-cy="observations"
                            />

                        </div>}

                        <div style={{ float: "right" }}>

                            <Button
                                variant="contained"
                                color="primary"
                                disabled={Boolean(isSubmitting || !isValid)}
                                onClick={submitForm}
                                data-cy="submit"
                            >
                                {submitName}
                            </Button>
                        </div>

                    </Form>
                )}
            </Formik>
        </Grid >
    );
};

export default CreateOrEditIssue;
