import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Activate from './components/Activate/Activate';
import GlobalFab from './components/GlobalFab/GlobalFab';
import Header from "./components/Header/Header";
import IssuesListView from "./components/IssuesListView/IssuesListView";
import Login from "./components/Login/Login";
import Logout from "./components/Logout/Logout";
import ProcessesListView from "./components/ProcessesListView/ProcessesListView";
import Profile from "./components/Profile/Profile";
import SignUp from "./components/SignUp/SignUp";
import Status from './components/Status/Status';
import TagsListView from "./components/TagsListView/TagsListView";
import UsersListView from './components/UsersListView/UsersListView';
import Viewer from "./components/Viewer/Viewer";
import Welcome from "./components/Welcome/Welcome";
import client from "./services/Client";
import { FabProvider } from './services/FabProvider';
import theme from './theme';

function App() {

    const [action, setAction] = useState();

    return (
        <ApolloProvider client={client}>
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                    <Router>
                        <FabProvider value={{ action, setAction }}>
                            <Header />
                            <Route exact path="/" component={IssuesListView} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/logout" component={Logout} />
                            <Route exact path="/status" component={Status} />
                            <Route exact path="/welcome" component={Welcome} />
                            <Route exact path="/activate" component={Activate} />
                            <Route exact path="/signup" component={SignUp} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/issues" component={IssuesListView} />
                            <Route exact path="/tags" component={TagsListView} />
                            <Route exact path="/processes" component={ProcessesListView} />
                            <Route exact path="/users" component={UsersListView} />
                            <Route exact path="/viewer/:code/:version?" component={Viewer} />
                            <GlobalFab />
                        </FabProvider>
                    </Router>
                </SnackbarProvider>
            </MuiThemeProvider>
        </ApolloProvider>
    );
}

export default App;
