import { useQuery } from '@apollo/react-hooks';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import ClampLines from 'react-clamp-lines';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import { PROCESSES } from '../../services/Queries';
import { fetchOrganization } from '../../utils/Utils';
import CreateProcess from '../CreateProcess/CreateProcess';
import CustomModal from '../CustomModal/CustomModal';
import EditProcess from '../EditProcess/EditProcess';
import EmptyState from '../EmptyState/EmptyState';
import Loading from '../Loading/Loading';
import ProcessDetails from '../ProcessDetails/ProcessDetails';
import Search from '../Search/Search';
import styles from './ProcessesListView.module.scss';

const ProcessesFilterBar = (props: any) => {
    return (
        <AppBar position="static" color="transparent">
            <Toolbar>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={2}>
                    <Grid item xs={2}>
                        <Search value={props.search} onChange={props.onChange} placeholder="pesquisar nome" />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const ProcessesList = ({ processes }: { processes: any }) => (

    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {processes.map((process: any) => (
                    <TableRow key={process.id}>
                        <TableCell component="th" scope="process">
                            {process.id}
                        </TableCell>
                        <TableCell component="th" scope="process">
                            {process.name}
                        </TableCell>
                        <TableCell align="left" className={styles.Description}>
                            <Tooltip title={process.description}>
                                <span>
                                    {process.description}
                                </span>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const ProcessPreview = (props: any) => {
    return (
        <Card className={styles.ProcessPreview} onClick={() => props.view(props.process)}>
            <CardHeader title={'#' + props.process.id} subheader={props.process.name} />
            <CardContent>
                <ClampLines
                    text={props.process.description || ''}
                    id={props.process.id}
                    lines={3}
                    ellipsis="..."
                    buttons={false}
                    innerElement="p"
                    className={styles.Description}
                />
            </CardContent>
        </Card>
    );
};

const ProcessesGrid = ({ processes, onOrder, view }: { processes: any, onOrder: any, view: any }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = (event: any) => {
        setIsMenuOpen(false);
    };

    const handleSort = (field: string, direction: string) => {
        setIsMenuOpen(false);
        onOrder(field, direction);
    };

    if (processes.length === 0) {
        return <EmptyState />
    }

    return (
        <Grid container justify="center" className={styles.ProcessesGrid}>
            <Grid container item xs={12}>
                <IconButton onClick={handleProfileMenuOpen}>
                    <Tooltip title="ordenar">
                        <SortIcon />
                    </Tooltip>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleSort('id', 'ASC')}>ID (ascendente)</MenuItem>
                    <MenuItem onClick={() => handleSort('id', 'DESC')}>ID (descendente)</MenuItem>
                </Menu>

            </Grid>
            <Grid container item xs={10} spacing={4}>
                {processes.map((process: any) => (
                    <Grid item xs={3} key={process.id}>
                        <ProcessPreview process={process} view={view} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};


const ProcessesListView = () => {

    useGuestRedirection();

    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [filters, setFilters] = useState({ search: "", view: 'GRID', page: 1, organization: fetchOrganization() });
    const [viewProcess, setViewProcess] = useState();
    const { data, loading, refetch } = useQuery(PROCESSES, { variables: filters });

    useGlobalFab(() => setCreateModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    const onComplete = () => {
        setCreateModalOpen(false);
        refetch();
    };

    const handleChange = (event: any) => {
        const name = event.target ? event.target.name : 'view';
        const value = event.target ? event.target.value : event.view;
        if (value === '') {
            const newFilters = { ...filters };
            // @ts-ignore
            delete newFilters[name];
            setFilters({ ...newFilters });
        } else {
            setFilters({ ...filters, [name]: "" + value });
        }

    };

    const handleOrder = (field: string, direction: string) => {
        // @ts-ignore
        setFilters({ ...filters, order: { field, direction } });
    };

    const handlePagination = (event: any, page: any) => {
        setFilters({ ...filters, page });
    };

    const handleViewProcess = (process: any) => {
        setViewModalOpen(true);
        setViewProcess(process);
    };

    const handleEditIssue = () => {
        setEditModalOpen(true);
    }

    const processes = data.processes.results;

    return (
        <>
            <ProcessesFilterBar search={filters.search} onChange={handleChange} />
            {filters.view === 'LIST' ? <ProcessesList processes={processes} /> : <ProcessesGrid processes={processes} onOrder={handleOrder} view={handleViewProcess} />}
            <Grid container justify="center" className={styles.Pagination}>
                {processes.length > 0 && <Pagination count={Math.ceil(data.processes.count / 25)} page={filters.page} onChange={handlePagination} />}
            </Grid>
            <CustomModal
                isActive={isCreateModalOpen}
                title="Create Process"
                handleClose={() => setCreateModalOpen(false)}>
                <CreateProcess onComplete={onComplete} />
            </CustomModal>
            <CustomModal
                isActive={isViewModalOpen}
                title=""
                showClose={false}
                handleClose={() => setViewModalOpen(false)}>
                <ProcessDetails
                    id={viewProcess?.id}
                    onComplete={() => setViewModalOpen(false)}
                    // @ts-ignore
                    onEdit={handleEditIssue} />
            </CustomModal>
            <CustomModal
                isActive={isEditModalOpen}
                title="Edit Issue"
                handleClose={() => setEditModalOpen(false)}>
                <EditProcess
                    id={viewProcess?.id}
                    onComplete={() => {
                        refetch();
                        setViewModalOpen(false);
                        setEditModalOpen(false);
                    }}
                />
            </CustomModal>
        </>
    );
};

export default ProcessesListView;
