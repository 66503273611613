import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from "yup";
import { useCreateTagMutation } from '../../generated/graphql';
import { errorParser, fetchOrganization } from '../../utils/Utils';

const CreateTag = ({ onComplete }: { onComplete: any }) => {

    const [createTag] = useCreateTagMutation();

    const submit = (values: any) => {
        return createTag({ variables: { data: { ...values } } });
    };

    const createTagSchema = Yup.object().shape({
        name: Yup.string().required('Campo Obrigatório'),
    });

    return (
        <Formik
            initialValues={{
                name: '',
                organization: fetchOrganization()
            }}
            validationSchema={createTagSchema}
            onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                // @ts-ignore
                submit(values).then((response) => {
                    setSubmitting(false);
                    if (response?.data?.createTag?.errors) {
                        const e = errorParser(response);
                        setErrors(e);
                    } else {
                        resetForm();
                        onComplete();
                    }
                });
            }}
        >
            {({ isValid, errors, dirty, submitForm, isSubmitting }) => (
                <Form>
                    <Grid container justify="center" alignItems="center">

                        <Grid container direction="column">

                            <Field
                                component={TextField}
                                name="name"
                                type="text"
                                label="Nome"
                                required
                                autoFocus
                                data-cy="name"
                            />

                            <div style={{ padding: '25px', color: 'red' }}>
                                <p data-cy="errors">
                                    {
                                        // @ts-ignore
                                        errors && errors.__all__
                                    }
                                </p>
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                // @ts-ignore
                                disabled={Boolean(isSubmitting || !isValid | !dirty)}
                                onClick={submitForm}
                                data-cy="submit"
                            >
                                Submeter
			    </Button>

                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CreateTag;
