import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { OrderDirection, useGetIssuesQuery } from '../../generated/graphql';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import { fetchOrganization } from '../../utils/Utils';
import CreateOrEditIssue from '../CreateOrEditIssue/CreateOrEditIssue';
import CustomModal from '../CustomModal/CustomModal';
import EmptyState from '../EmptyState/EmptyState';
import Filterbar from '../Filterbar/Filterbar';
import FormatDate from '../FormatDate/FormatDate';
import IssueDetails from '../IssueDetails/IssueDetails';
import IssuePreview from '../IssuePreview/IssuePreview';
import Loading from '../Loading/Loading';
import styles from './IssuesListView.module.scss';

const IssuesList = ({ issues }: { issues: any }) => (

    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Project</TableCell>
                    <TableCell align="left">User</TableCell>
                    <TableCell align="left">Nature</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">State</TableCell>
                    <TableCell align="left">Tag</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="right">Observations</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {issues.map((issue: any) => (
                    <TableRow key={issue.id}>
                        <TableCell component="th" scope="issue">
                            {issue.id}
                        </TableCell>
                        <TableCell component="th" scope="issue">
                            {issue.project && issue.project.name}
                        </TableCell>
                        <TableCell component="th" scope="issue">
                            {issue.user.name}
                        </TableCell>
                        <TableCell align="left">
                            {issue.nature}
                        </TableCell>
                        <TableCell align="left">
                            <FormatDate timestamp={issue.issueDate} />
                        </TableCell>
                        <TableCell align="left">
                            {issue.state}
                        </TableCell>
                        <TableCell align="left">
                            {issue.tag}
                        </TableCell>
                        <TableCell align="left" className={styles.Description}>
                            <Tooltip title={issue.description}>
                                <span>
                                    {issue.description}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="right" className={styles.Observations}>
                            <Tooltip title={issue.observations}>
                                <span>{issue.observations}</span>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);


const IssuesGrid = ({ issues, onOrder, view }: { issues: any, onOrder: (field: string, direction: string) => any, view: any }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = (event: any) => {
        setIsMenuOpen(false);
    };

    // const handleMenuAction = () => {
    //     setIsMenuOpen(false);
    //     // history.push('/profile');
    // };

    const handleSort = (field: string, direction: string) => {
        setIsMenuOpen(false);
        onOrder(field, direction);
    };

    if (issues.length === 0) {
        return <EmptyState />
    }

    return (
        <Grid container justify="center" className={styles.IssuesGrid}>
            <Grid container item xs={12}>
                <IconButton onClick={handleProfileMenuOpen}>
                    <Tooltip title="ordenar">
                        <SortIcon />
                    </Tooltip>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleSort('sequence', 'ASC')}>ID (ascendente)</MenuItem>
                    <MenuItem onClick={() => handleSort('sequence', 'DESC')}>ID (descendente)</MenuItem>
                </Menu>

            </Grid>
            <Grid container item xs={10} spacing={4}>
                {issues && issues.map((issue: any) => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={issue.id}>
                        <IssuePreview issue={issue} view={view} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

const IssuesListViewWrapper = () => {

    useGuestRedirection();

    const [filters, setFilters] = useState({ view: 'GRID', page: 1, order: { field: "sequence", direction: OrderDirection.Desc }, organization: fetchOrganization() });

    const handleChange = (event: any) => {
        const name = event.target ? event.target.name : 'view';
        const value = event.target ? event.target.value : event.view;

        if (!value) {
            const newFilters = { ...filters };
            // @ts-ignore
            delete newFilters[name];
            setFilters({ ...newFilters });
        } else {
            if (name === 'priority') {
                // @ts-ignore
                setFilters({ ...filters, priority: parseInt(value, 10), page: 1, });
            } else {
                setFilters({ ...filters, [name]: "" + value, page: 1, });
            }
        }

    };

    return (
        <>
            <Filterbar filters={filters} onChange={handleChange} />
            <IssuesListView filters={filters} setFilters={setFilters} />
        </>
    )
}


const IssuesListView = (props: any) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);

    const [viewIssue, setViewIssue] = useState();
    const { data, loading, refetch } = useGetIssuesQuery({ variables: props.filters })

    useGlobalFab(() => setModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    const onComplete = () => {
        setModalOpen(false);
        refetch();
    };


    const handleOrder = (field: string, direction: string) => {
        // @ts-ignore
        props.setFilters({ ...props.filters, order: { field, direction } });
    };

    const handlePagination = (_: any, page: number) => {
        props.setFilters({ ...props.filters, page });
    };

    const handleViewIssue = (issue: any) => {
        setViewModalOpen(true);
        setViewIssue(issue);
    };

    const handleEditIssue = () => {
        setEditModalOpen(true);
    };

    const handleCompletion = () => {
        refetch();
        setViewModalOpen(false);
        setEditModalOpen(false);
        return 'blah!'
    }

    const issues = data?.getIssues?.results;

    return (
        <>
            {props.filters.view === 'LIST' ? <IssuesList issues={issues} /> : <IssuesGrid issues={issues} onOrder={handleOrder} view={handleViewIssue} />}
            <Grid container justify="center" className={styles.Pagination}>
                {(issues && issues?.length > 0) && <Pagination count={data?.getIssues?.count ? Math.ceil(data.getIssues.count / 25) : 0} page={props.filters.page} onChange={handlePagination} />}
            </Grid>
            <CustomModal
                isActive={isModalOpen}
                handleClose={() => setModalOpen(false)}>
                {/* <CreateIssue onComplete={onComplete} /> */}
                <CreateOrEditIssue onComplete={onComplete} />
            </CustomModal>
            <CustomModal
                isActive={isViewModalOpen}
                title=""
                showClose={false}
                handleClose={() => setViewModalOpen(false)}>
                <IssueDetails
                    issue={viewIssue}
                    onComplete={() => setViewModalOpen(false)}
                    // @ts-ignore
                    onEdit={handleEditIssue} />
            </CustomModal>
            <CustomModal
                isActive={isEditModalOpen}
                handleClose={() => setEditModalOpen(false)}>
                {/* <EditIssue
                    issue={viewIssue}
                    onComplete={() => {
                    refetch();
                    setViewModalOpen(false);
                    setEditModalOpen(false);
                    }}
                    /> */}

                <CreateOrEditIssue onComplete={handleCompletion} issue={viewIssue} />

            </CustomModal>
        </>
    );
};

export default IssuesListViewWrapper;
