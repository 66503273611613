import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from "yup";
import { useCreateProcessWithOwnerMutation } from '../../generated/graphql';
import { errorParser } from '../../utils/Utils';
import OrganizationUsersSelector from '../OrganizationUsersSelector/OrganizationUsersSelector';

const CreateProcess = ({ onComplete }: { onComplete: any }) => {

    const [createProcess] = useCreateProcessWithOwnerMutation()


    const submit = (values: any) => {
        const user = values?.organizationUser?.user?.id
        return createProcess({
            variables: { data: { ...values, user, organization: "22" } }
        });
    };

    const createProcessSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo Obrigatório'),
    });

    return (
        <Formik
            initialValues={{
                name: '',
            }}
            validationSchema={createProcessSchema}
            onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                // @ts-ignore
                submit(values).then((response, loading, error) => {
                    setSubmitting(false);
                    if (response.data.createProcessWithOwner.errors) {
                        const e = errorParser(response);
                        setErrors(e);
                    } else {
                        resetForm();
                        onComplete();
                    }
                });
            }}
        >
            {({ isValid, errors, touched, dirty, submitForm, isSubmitting }) => (
                <Form>
                    <Grid container justify="center" alignItems="center">

                        <Grid container direction="column">

                            <Field
                                component={TextField}
                                name="name"
                                type="text"
                                label="Nome"
                                required
                                autoFocus
                                data-cy="name"
                            />

                            <Field
                                name="user"
                                as={OrganizationUsersSelector}
                            />

                            <div style={{ padding: '25px', color: 'red' }}>
                                <p data-cy="errors">
                                    {
                                        // @ts-ignore
                                        errors && errors.__all__
                                    }
                                </p>
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                // @ts-ignore
                                disabled={Boolean(isSubmitting || !isValid | !dirty)}
                                onClick={submitForm}
                                data-cy="submit"
                            >
                                Submeter
			    </Button>

                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CreateProcess;
